<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div class="title">
        <img src="@/assets/img/logo.png" alt="" class="logo">
        <span>Crystal Ball Management System</span>
      </div>
      <div class="logout">
        <img class="usericon" src="@/assets/img/user-filling.png" alt="">
        <el-dropdown trigger="click" @command="handleCommand">
          <span>{{userInfo?userInfo.username:''}}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="e">修改个人资料</el-dropdown-item>
            <el-dropdown-item command="d">修改密码</el-dropdown-item>
            <el-dropdown-item command="u">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </div>
    </el-header>
    <el-container>
      <!-- 侧边区域 -->
      <el-aside width="auto">
        <div class="toggle-button" @click="toggleCollapse" :style="{ width: isCollapse ? '64px' : '100%'}">|||</div>
        <el-menu :default-active="activeMenItem" background-color="#0f2950" text-color="#fff" active-text-color="#409eff" :collapse="isCollapse" :collapse-transition='false' router
          class="el-menu-vertical-demo" :unique-opened="true">
          <!-- 动态侧边菜单列表 -->
          <template v-if="menuList.length > 0">
            <AsideItem v-for="route in menuList" :key="route.name" :item="route" :isCollapse="isCollapse"></AsideItem>
          </template>
        </el-menu>
      </el-aside>

      <!-- 主体区域 -->
      <el-main>
        <div class="TagBox">
          <!-- tags区域 -->
          <tagsview></tagsview>
        </div>
        <!-- 占位符 -->
        <keep-alive :include="cachedViews">
          <router-view class="loaing-area"></router-view>
        </keep-alive>
      </el-main>
    </el-container>

    <!-- 修改用户密码 -->
    <el-dialog title="重置密码" :visible.sync="dialogFormVisible" @close="hiddlendialog">
      <el-form :model="form" ref="form" :rules="rulesForm">
        <el-form-item label="登录名称" label-width="100px">
          <el-input v-model="userInfo.username" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="旧密码" label-width="100px" prop="oldPassword">
          <el-input v-model.trim="form.oldPassword" autocomplete="off" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" label-width="100px" prop="newPassword">
          <el-input v-model.trim="form.newPassword" autocomplete="off" show-password></el-input>
        </el-form-item>
        <el-form-item label="再次确认" label-width="100px" prop="onceNewPassword">
          <el-input v-model.trim="form.onceNewPassword" autocomplete="off" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resetPassword">确 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 修改用户昵称 -->
    <el-dialog title="修改账户昵称" :visible.sync="Visible" @close="hiddlenClose">
      <el-form :model="userForm" ref="userForm" style="width:90%" :rules="userules">
        <el-form-item label="账户昵称" label-width="100px" prop="nickName">
          <el-input v-model="userForm.nickName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resetUserName">确 定</el-button>
        <el-button @click="Visible = false">取 消</el-button>
      </div>
    </el-dialog>
  </el-container>

</template>

<script>
import tagsview from "./tagsview.vue";
import { mapState } from "vuex";
import { exit, resetpassword } from "@/api/index.js";
import { changeUser } from "@/api/user.js";
import "@/assets/css/icfont/iconfont.css";
import AsideItem from "@/components/commons/asideItem";
import { resetRouter } from "@/router/index.js";

export default {
  name: "",
  components: { tagsview, AsideItem },
  data() {
    return {
      AdminAuthority: true, // 是否是管理员
      AdminBillAuthority: false, // 是否具有账单管理相关权限

      isCollapse: false,
      activePath: "",
      userInfo: {},
      cachedViews: this.$store.state.tab.cachedViews,

      dialogFormVisible: false, // 修改密码弹窗

      form: {
        oldPassword: "", // 旧密码
        newPassword: "", // 新密码
        onceNewPassword: "", // 再次确认
      },
      rulesForm: {
        oldPassword: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPassword: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        onceNewPassword: [{ required: true, message: "请输入新密码", trigger: "blur" }],
      },
      Visible: false, // 修改个人昵称
      userForm: {
        nickName: "",
      },
      userules: {
        nickName: [{ required: true, message: "账户昵称不能为空", trigger: "blur" }],
      },
    };
  },
  computed: {
    // 引入vuex中的taglist数据
    ...mapState({
      tagList: (state) => state.tab.tagList,
      menuList: (state) => state.menuList.menuList,
    }),
    activeMenItem() {
      console.log("刷新", this.$store.state.tab.isAdmin);
      if (this.$route.path == "/addcarinfo" || this.$route.path == "/editcarinfo") {
        return "/property";
      } else {
        return this.$route.path;
      }
    },
  },
  created() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"))[0].userInfo;

    this.AdminAuthority = JSON.parse(window.sessionStorage.getItem("userInfo"))[0].userInfo.hasAdmin;

    // 判断是否具有账单管理的权限
    this.AdminBillAuthority = JSON.parse(window.sessionStorage.getItem("userInfo"))[0].userInfo.hasCwAdmin;

    // 判断用户角色--默认首页
    // if (this.AdminAuthority) {
    //   this.$router.push({ path: "/user" });
    // } else {
    //   this.$router.push({ path: "/property" });
    // }
  },
  activated() {},
  methods: {
    // 用户下拉框
    handleCommand(command) {
      console.log(command);
      if (command == "d") {
        // 修改密码
        this.dialogFormVisible = true;
      } else if (command == "u") {
        // 退出登录
        exit().then((res) => {
          // console.log(res);
          window.sessionStorage.clear();
          this.$store.commit("clearTaglist");
          this.$router.push("/login");
          resetRouter();
        });
      } else if (command == "e") {
        this.userForm.nickName = this.userInfo.nickName;
        this.Visible = true;
      }
    },
    resetPassword() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.newPassword === this.form.onceNewPassword) {
            const { oldPassword, newPassword } = this.form;
            resetpassword({ oldPassword, newPassword }).then((res) => {
              console.log(res);
              this.$message.success("密码修改成功,请重新登录系统");
              window.sessionStorage.clear();
              this.$store.commit("clearTaglist");
              this.$router.push("/login");
              resetRouter();
            });
          } else {
            this.$message.warning("两次密码不一致,请重新输入");
          }
        }
      });
    },
    // 隐藏弹窗--重置数据
    hiddlendialog() {
      this.$refs.form.resetFields();
    },
    hiddlenClose() {
      this.$refs.userForm.resetFields();
      this.userForm.nickName = "";
    },
    // 确认修改用户昵称
    resetUserName() {
      this.$refs.userForm.validate((valid) => {
        console.log(valid);
        if (valid) {
          let id = this.userInfo.id;
          changeUser({ nickName: this.userForm.nickName }, id).then((res) => {
            if (res.code == 10000) {
              console.log(res);
              this.$message.success("修改成功");
              this.Visible = false;

              // 更新本地缓存用户信息
              let obj = this.userInfo;
              obj.nickName = this.userForm.nickName;
              const userInfo = JSON.stringify([
                {
                  userInfo: obj,
                },
              ]);
              window.sessionStorage.setItem("userInfo", userInfo);
            }
          });
        }
      });
    },
    // 菜单的折叠/展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style lang='less' scoped>
.home-container {
  height: 100%;
  .el-header {
    background-color: @headers_color;
    color: @text_color;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      .logo {
        height: 60px;
      }
      span {
        font-size: 22px;
        margin-left: 15px;
        font-weight: 900;
      }
    }
    .logout {
      display: flex;
      align-items: center;
      span {
        // margin: 0 20px;
        color: aliceblue;
      }
      .el-dropdown {
        color: aliceblue;
        cursor: pointer;
      }
      .usericon {
        width: 34px;
        margin-right: 8px;
      }
    }
  }
  .el-aside {
    background-color: @leftAside_color;
    .el-menu {
      border-right: none;
    }
    .toggle-button {
      background-color: #0713249a;
      border-bottom: 1px solid #367fa988;
      text-align: center;
      font-size: 8px;
      color: aliceblue;
      cursor: pointer;
      line-height: 24px;
      letter-spacing: 0.2em;
      font-weight: 800;
    }
    .dashboard {
      padding-top: 4px;
      display: inline-block;
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
      width: 200px;
      min-height: 400px;
    }
  }
  .el-main {
    background-color: @main_color;
    padding: 0;
    position: relative;
    .TagBox {
      flex: 1;
      // height: 32px;
      // padding: 4px;
      background-color: white;
      box-shadow: -1px -2px 5px 0px rgba(16, 16, 16, 0.75);
      -webkit-box-shadow: 0px 5px 14px 0px rgba(65, 65, 65, 0.39);
      -moz-box-shadow: -1px -2px 5px 0px rgba(16, 16, 16, 0.75);
      position: absolute;
      width: 100%;
      z-index: 1000;
      padding-right: 20px;
      box-sizing: border-box;
    }
  }
}
</style>
