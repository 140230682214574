<template>
  <div id="role">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">角色管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <!-- 搜索区域视图 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input clearable v-model="queryFrom.roleName" placeholder="角色名" class="input-with-select">
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input v-model="queryFrom.keyword" clearable placeholder="角色关键字" class="input-with-select">
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button class="searchButton" type="primary" size="medium" @click="searchList()">查询</el-button>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <div class="table_header">
        <el-button type="primary" round size="mini" icon="el-icon-plus" @click="roleVisible=true">新增</el-button>
      </div>
      <el-table :data="roleListData" style="width: 100%;fontSize: 14px;" :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,backgroundColor:'#eff3f8'}" border>
        <el-table-column prop="id" label="角色ID" width="80">
        </el-table-column>
        <el-table-column prop="roleName" label="角色名称" width="180">
        </el-table-column>
        <el-table-column prop="keyword" label="角色字符">
        </el-table-column>
        <el-table-column prop="description" label="说明">
        </el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editUserinfRole(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="removeRole(scope.row.id)">删除</el-button>
            <el-popover popper-class="operate-popover" placement="left" trigger="click">
              <el-button size="mini" icon="el-icon-finished" @click="setoutPermit(scope.row)">分配权限</el-button>
              <el-button size="mini" icon="el-icon-s-unfold" @click="setoutMenu(scope.row)">分配菜单</el-button>
              <el-button slot="reference" size="mini" type="success" style="#1c84c6;marginLeft:10px" icon="el-icon-edit-outline">更多操作</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum" :page-sizes="[1, 5, 10, 20]" :page-size="size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>

    <!-- 新增/编辑角色弹窗 -->
    <el-dialog width="40%" title="角色信息" :visible.sync="roleVisible" @close='hiddlenCarDialog'>
      <el-form :model="roleForm" ref="roleFormRef" :rules="roleFormRules">
        <el-form-item label="角色名" label-width="100px" prop="roleName">
          <el-input :class="rolesId>0?'updateRole':''" v-model="roleForm.roleName" autocomplete="off" style="width:70%"></el-input>
        </el-form-item>
        <el-form-item label="角色关键字" label-width="100px" prop="keyword">
          <el-input :class="rolesId>0?'updateRole':''" v-model="roleForm.keyword" autocomplete="off" style="width:70%"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" label-width="100px" prop="description">
          <el-input :class="rolesId>0?'updateRole':''" type="textarea" v-model="roleForm.description" autocomplete="off" style="width:70%"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="roleVisible = false">取 消</el-button>
        <el-button type="primary" @click="roleApi" :loading="submitLoading">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 分配权限弹窗 -->

    <el-dialog width="40%" title="权限信息" :visible.sync="PermitVisible" class="PremitRremitDialog powerDialog" @close="HiddlenPermitDialog">
      <el-descriptions>
        <el-descriptions-item label="角色名称">{{RoleInfo.roleName}}</el-descriptions-item>
        <el-descriptions-item label="说明">{{RoleInfo.description}}</el-descriptions-item>
      </el-descriptions>
      <el-tree ref="tree" :check-strictly="true" :data="permitListData" show-checkbox node-key="id" :default-expanded-keys="expandedPermit" :default-checked-keys="checkedPermit" :props="defaultProps">
      </el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click="PermitVisible = false">取 消</el-button>
        <el-button type="primary" @click="bindPermitApi">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 分配菜单 -->
    <el-dialog width="40%" title="菜单信息" :visible.sync="menuVisible" class="PremitRremitDialog menuDialog" @close="HiddlenMenuDialog">
      <el-descriptions>
        <el-descriptions-item label="角色名称">{{RoleInfo.roleName}}</el-descriptions-item>
        <el-descriptions-item label="说明">{{RoleInfo.description}}</el-descriptions-item>
      </el-descriptions>
      <el-tree ref="treeMenu" :data="menuListData" show-checkbox node-key="name" :default-expand-all="true" :default-checked-keys="checkedMenu" :props="defaultMenuProps">
      </el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click="menuVisible = false">取 消</el-button>
        <el-button type="primary" @click="bindMenuApi">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
    
<script>
import {
  RoleList,
  addRole,
  updateRole,
  deleteRole,
  bindRolePermint,
  unbindRolePermint,
  getRoleMenu,
  bindRoleMenus,
} from "@/api/role.js";
import { PermitList, RoleIdPermitList } from "@/api/permit.js";

import { Loading } from "element-ui";
export default {
  name: "",
  data() {
    return {
      roleListData: [], //角色列表数据
      queryFrom: {
        roleName: "",
        keyword: "",
      },
      pagenum: 1,
      size: 10,
      total: 0,

      rolesId: -1, //角色id
      roleForm: {
        description: "",
        keyword: "",
        roleName: "",
      }, //角色信息表单
      roleVisible: false,
      roleFormRules: {
        roleName: [
          { required: true, message: "请填写角色名", trigger: "blur" },
        ],
        keyword: [
          { required: true, message: "请填写角色关键字", trigger: "blur" },
        ],
      },

      PermitVisible: false,
      permitListData: [], //tree组件数据
      checkedPermit: [], //已经选中的权限
      expandedPermit: [], //默认展开项
      defaultProps: {
        children: "children",
        label: "label",
      },
      RoleInfo: {}, //角色信息
      menuVisible: false, 
      menuListData: [], //tree组件数据 -- 菜单
      checkedMenu: [], //已经选中的权限 -- 菜单
      defaultMenuProps: {
        children: "children",
        label: "title",
      },
      submitLoading: false
    };
  },
  computed: {
    //动态遍历需要传的查询参数
    queryFromData() {
      let date = {};
      for (const key in this.queryFrom) {
        if (this.queryFrom[key] != "") {
          date[key] = this.queryFrom[key];
        }
      }
      return date;
    },
  },
  created() {
    this.getRoleList();
  },
  methods: {
    getRoleList() {
      RoleList(this.queryFromData, this.pagenum, this.size).then((res) => {
        console.log(res);
        this.roleListData = res.data.dataList;
        this.total = res.data.total;
      });
    },
    //刷新数据
    refreshData() {
      this.size = 10;
      this.pagenum = 1;
      this.getRoleList();
    },
    searchList() {
      this.refreshData();
    },
    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.size = newSize;
      this.getRoleList(); //发起数据请求
    },
    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.getRoleList(); //发起数据请求
    },
    //点击编辑按钮
    editUserinfRole(role) {
      const { id, roleName, keyword, description } = role;
      console.log(role);
      this.roleForm.roleName = roleName;
      this.roleForm.keyword = keyword;
      this.roleForm.description = description;
      this.roleVisible = true;
      this.rolesId = id;
    },

    //确认提交角色
    roleApi() {
      this.$refs.roleFormRef.validate((valid) => {
        console.log(valid);
        if (valid) {
          this.submitLoading = true;
          //添加角色
          if (this.rolesId < 0) {
            addRole(this.roleForm).then((res) => {
              console.log(res);
              this.roleVisible = false;
              this.$message.success("角色新增成功");
              this.refreshData();
              this.submitLoading = false;
            });
          } else {
            //编辑角色
            updateRole(this.roleForm, this.rolesId).then((res) => {
              console.log(res);
              this.roleVisible = false;
              this.$message.success("角色编辑成功");
              this.refreshData();
              this.submitLoading = false;
            });
          }
        }
      });
    },

    //删除角色
    removeRole(id) {
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteRole(id).then((res) => {
            console.log(res);
            this.refreshData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //点击分配权限按钮
    setoutPermit(roleinfo) {
      let loadingQuery = Loading.service({
        lock: true,
        text: "",
        target: document.querySelector(".powerDialog .el-dialog"),
      });
      const { id, roleName, description } = roleinfo;
      this.RoleInfo.roleName = roleName;
      this.RoleInfo.description = description;
      this.rolesId = id;
      RoleIdPermitList(id).then((result) => {
        console.log(result);
        this.permitListData = result.data;
        this.disposePermit(result.data);
        loadingQuery.close();
      });

      this.PermitVisible = true;
    },
    //确认绑定权限
    bindPermitApi() {
      let loadingQuery = Loading.service({
        lock: true,
        text: "",
        target: document.querySelector(".powerDialog .el-dialog"),
      });
      console.log(this.$refs.tree.getCheckedKeys());
      let permitIds = this.$refs.tree.getCheckedKeys();
      bindRolePermint({ roleId: this.rolesId, permitIds }).then((res) => {
        console.log(res);
        this.$message.success("角色权限分配成功");
        this.PermitVisible = false;
        loadingQuery.close();
      });
    },
    //递归循环tree数据
    disposePermit(data) {
      // console.log(data);
      data.forEach((item) => {
        if (item.children.length > 0) {
          this.disposePermit(item.children);
        }

        if (item.checked) {
          this.checkedPermit.push(item.id);
          if (item.parentId != null) {
            let result = this.expandedPermit.findIndex(
              (it) => it === item.parentId
            );
            result === -1 ? this.expandedPermit.push(item.parentId) : "";
            console.log(result);
          }
        }
      });
    },

    // 隐藏treeshuxing2弹窗
    HiddlenPermitDialog() {
      this.RoleInfo = {};
      this.checkedPermit = [];
      this.expandedPermit = [];
      this.rolesId = -1;
    },
    //隐藏添加/编辑表单那弹窗
    hiddlenCarDialog() {
      console.log("执行");
      this.$refs.roleFormRef.clearValidate(); ///重置新增用户表单
      Object.keys(this.roleForm).forEach((key) => (this.roleForm[key] = ""));
      this.rolesId = -1;
    },

    disposeMenu(data) {   
      data.forEach((item) => {
        if (item.children && item.children.length > 0) {
          this.disposeMenu(item.children);
        }
        if (item.role && (!item.children || item.children.length == 0)) {
          this.checkedMenu.push(item.name);
        }
      });
    },
    // 分配菜单数据
    setoutMenu(roleinfo) {
      let loadingQuery = Loading.service({
        lock: true,
        text: "",
        target: document.querySelector(".menuDialog .el-dialog"),
      });
      const { id, roleName, description } = roleinfo;
      this.RoleInfo.roleName = roleName;
      this.RoleInfo.description = description;
      this.rolesId = id;
      getRoleMenu({roleId: id}).then((result) => {
        console.log(result);
        const rootTree = [{
          name: "root",
          title: "全部菜单",
          icon: "",
          role: true, //是否有权限
          children: [],
        }]
        rootTree[0].children = result.data; 
        console.log(rootTree);
        
        this.menuListData = rootTree;
        this.disposeMenu(result.data);
        loadingQuery.close();
      });

      this.menuVisible = true;
    },
    bindMenuApi() {
      let loadingQuery = Loading.service({
        lock: true,
        text: "",
        target: document.querySelector(".menuDialog .el-dialog"),
      });
      const checkedNodes = this.$refs.treeMenu.getCheckedNodes(false, true);
      const checkedMenus = checkedNodes.filter(node => node.name !== 'root').map(node => node.id );
      console.log(this.$refs.treeMenu.getCheckedNodes(false, true), checkedMenus);
      const params = {
        roleId: this.rolesId,
        menuIds: checkedMenus
      }
      bindRoleMenus(params).then((res) => {
        this.$message.success("菜单分配成功!");
        this.menuVisible = false;
        loadingQuery.close();
      })
    },
    HiddlenMenuDialog() {
      this.RoleInfo = {};
      this.menuListData = [];
      this.checkedMenu = [];
      this.rolesId = -1;
    }

  },
};
</script>
    
<style lang='less' scoped>
#role {
  padding: 60px 20px 20px 20px;
}
.el-tree {
  margin-top: 10px;
}
.PremitRremitDialog /deep/ .el-descriptions-item__label {
  font-weight: 900 !important;
}
</style>