<template>
  <div class="lease">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">租赁合同管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <!-- 搜索区域视图 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input v-model="queryFrom.contractNo" placeholder="合同编号" class="input-with-select" clearable>
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="queryFrom.search" clearable placeholder="客户姓名/手机号" class="input-with-select">
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.cityId" clearable placeholder="所在城市">
            <el-option :key="item.cityId" v-for="item in optionsCity" :label="item.cityName" :value="item.cityId"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.companyId" clearable placeholder="所属公司">
            <el-option :key="item.companyId" v-for="item in optionsCompany" :label="item.companyName" :value="item.companyId"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.contractStatus" clearable placeholder="合同状态">
            <el-option :key="item.value" v-for="item in optionsStatus" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.auditState" clearable placeholder="审核状态">
            <el-option :key="item.value" v-for="item in auditOptionsStatus" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-date-picker v-model="queryFrom.signDate" value-format="yyyy-MM-dd" type="date" placeholder="签订日期">
          </el-date-picker>
        </el-col>
      </el-row>
      <div style="width:100%;height:20px"></div>
      <el-row>
        <el-col :span="5">
          <el-date-picker v-model="queryFrom.startDateStr" value-format="yyyy-MM" type="month" placeholder="起租日">
          </el-date-picker>
        </el-col>
        <el-col :span="10">
          <el-button class="searchButton" type="primary" size="medium" @click="searchList()">查询</el-button>
          <div class="deadlineChecked">
            <el-checkbox v-model="queryFrom.expiresFlag">即将到期合同(30天)</el-checkbox>
            <el-checkbox v-model="queryFrom.notPlanFlag">无还款计划表合同</el-checkbox>
          </div>
        </el-col>
        <el-col :span="3">
        </el-col>
        <el-col :span="6">
        </el-col>
      </el-row>

    </el-card>

    <el-card>
      <div class="table_header" v-if="!isOnlyView">
        <el-button type="primary" round size="mini" icon="el-icon-plus" @click="addCarItem()">新增</el-button>
        <el-button type="warning" round size="mini" icon="el-icon-download" @click="DownExcel()">导出</el-button>
        <el-button round size="mini" icon="el-icon-refresh" @click="refresh()">刷新</el-button>
        <!-- <el-button icon="el-icon-upload" size="mini" round class="template-btn" @click="uploadMembers()">
          <span>数据导入</span>
          <input type="file" style="display: none;" id="file" ref="files" @change="upload" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" />
        </el-button> -->
      </div>

      <!-- 租赁合同表格数据 -->
      <div class="table-box">
        <el-table @sort-change="sortWarnTime" :data="leaseListData" style="width: 100%" border stripe :header-cell-style="{
            color: '#333',
            fontFamily: 'MicrosoftYaHeiUI',
            fontSize: '14px',
            fontWeight: 900,
            backgroundColor: '#eff3f8',
          }">
          <el-table-column type="index" label="序" width="50">
            <template slot-scope="scope">
              <span>{{ (pagenum - 1) * size + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="contractNo" label="合同编号" width="180">
          </el-table-column>
          <el-table-column prop="carframeNo" label="车架号" width="180">
          </el-table-column>
          <el-table-column prop="carType" label="适用车型" width="100"> </el-table-column>
          <el-table-column label="公司" width="120">
            <template slot-scope="scope">
              {{scope.row.cityName}}{{scope.row.companyName}}
            </template>
          </el-table-column>
          <el-table-column prop="customer" label="客户"> </el-table-column>
          <!-- <el-table-column prop="customerPhone" label="客户电话" width="140">
          </el-table-column> -->
          <el-table-column prop="contractAmount" label="合同金额(元)" width="120">
          </el-table-column>
          <el-table-column prop="monthRent" label="月租金(元)" width="100">
          </el-table-column>
          <!-- <el-table-column prop="irr" label="IRR(%)" width="100">
          </el-table-column> -->
          <el-table-column label="到期提醒(天)" sortable="custom">
            <template slot-scope="scope">
              {{ computerDate(scope.row.endDate,scope.row.contractStatus) }}
            </template>
          </el-table-column>
          <el-table-column prop="signDate" label="签订日期" width="120">
          </el-table-column>
          <el-table-column prop="createDate" label="创建时间" width="100">
          </el-table-column>
          <!-- <el-table-column prop="auditState" label="期数" width="60">
          </el-table-column> -->
          <el-table-column label="审核状态" width="120">
            <template slot-scope="scope" v-if="scope.row.auditState != null">
              <el-tag :type="scope.row.auditState == 0 ? 'info' : tagsStyle[scope.row.auditState]">
                {{ auditOptionsStatus[scope.row.auditState].label }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="合同状态" width="120">
            <template slot-scope="scope" v-if="scope.row.contractStatus">
              <el-tag :type="tagsStyle[scope.row.contractStatus - 1]">
                {{ scope.row.contractStatusStr }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column v-if="!isOnlyView" label="操作" width="280" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="editCarinfo(scope.row.id)">编辑</el-button>
              <el-button size="mini" type="success" icon="el-icon-s-claim" @click="renew(scope.row.id)">续签</el-button>

              <el-popover placement="top" width="160" trigger="click">
                <el-button size="mini" type="danger" icon="el-icon-delete" @click="removeLese(scope.row.id)">删除</el-button>

                <el-button size="mini" type="success" icon="el-icon-tickets" @click="ReadLese(scope.row.id)">查看</el-button>
                <el-button slot="reference" size="mini" style="#67c23a;marginLeft:10px" icon="el-icon-more"></el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column v-else label="操作" width="120" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="success" icon="el-icon-tickets" @click="ReadLese(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum" :page-sizes="[1, 5, 10, 20]" :page-size="size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>

    <!-- 添加编辑搜索表单弹窗 -->
    <el-dialog :visible.sync="dialogFormVisible" width="84%" @close="hiddlenDialog" :close-on-click-modal="false">
      <template slot="title">
        <div>
          <span style="font-size: 20px">合同信息 </span>
          <el-link type="primary" v-if="this.leaseId > 0" @click="ShowVhistory" style="fontweight: 900">
            V{{ leaseVersion }}
          </el-link>
        </div>
      </template>
      <div v-loading="formLoading">
        <el-form :model="leaseform" ref="ruleForm" :rules="rulesLeaseform" class="leaseFrominfo" label-width="110px" label-position="left">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="合同编号" prop="contractNo">
                <el-input v-model.trim="leaseform.contractNo" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="适用车辆" prop="carframeNo">
                <el-input autocomplete="off" suffix-icon="el-icon-search" v-model="leaseform.carframeNo" @focus="showCarinfo" placeholder="请选择适用车辆ID">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户" prop="customer">
                <el-input v-model="leaseform.customer" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="客户电话" prop="customerPhone">
                <el-input v-model="leaseform.customerPhone" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户备用电话" prop="customerSparePhone">
                <el-input v-model="leaseform.customerSparePhone" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="身份证号码" prop="idcardNo">
                <el-input v-model="leaseform.idcardNo" autocomplete="off" clearable @input="idcardNoChange"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="性别">
                <el-select v-model="leaseform.sex" placeholder="请选择性别">
                  <el-option label="男" value="男"></el-option>
                  <el-option label="女" value="女"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="驾驶证到期日">
                <el-date-picker v-model="leaseform.dreverDueDate" value-format="yyyy-MM-dd" type="date" placeholder="驾驶证到期日">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="月租金(元)" prop="monthRent">
                <el-input :disabled="false" class="numrule" type="number" v-model="leaseform.monthRent" autocomplete="off" @input="changeMonthRent"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="租赁期数" prop="periodsTotal">
                <el-input :disabled="leaseFormPaymentState" :class="isOnceperiodsTotal?'isnumberInput':'numberInput'" min="0" type="number" v-model="leaseform.periodsTotal" autocomplete="off"
                  @input="changePeriodsTotal">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="合同金额(元)" prop="contractAmount">
                <el-input class="Money_input numrule" type="number" v-model="leaseform.contractAmount" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="其他费用(元)" prop="otherAmount">
                <el-input class="Money_input numrule" type="number" v-model="leaseform.otherAmount" autocomplete="off" @input="changeOtherAmount"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item prop="startDate" class="slotDatePicker">
                <div class="slot_label">
                  <span slot="label">
                    <el-popover placement="right" width="200" trigger="click">
                      <el-table style="width: 100%" border stripe :header-cell-style="{
                          fontSize: '14px',
                          fontWeight: '600',
                        }" :data="dueDateList" class="dueDateList">
                        <el-table-column width="60" property="index" label="期数"></el-table-column>
                        <el-table-column align="center" property="date" label="还款日"></el-table-column>
                      </el-table>
                      <!-- <el-link v-show="!(this.leaseId > 0 && this.isRenew == false)" slot="reference" type="primary" @click="showdueDate">每期还款日</el-link> -->
                    </el-popover>
                    <br />
                    <span class="slot_span">起租日期&emsp;</span>
                  </span>
                </div>
                <el-date-picker :disabled="leaseFormPaymentState" @change="startDateChange" width="100px" v-model="leaseform.startDate" value-format="yyyy-MM-dd" type="date" placeholder="起租日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="endDate" class="slotDatePicker">
                <div class="slot_label">
                  <span slot="label">
                    <el-popover placement="top-start" width="100" trigger="click" :content="'距离还款日还有' + residueDate + '天!!!'">
                      <el-link slot="reference" type="primary" @click="showresidueDate">到期提醒</el-link>
                    </el-popover>
                    <br />
                    <span class="slot_span">结束日期&emsp;</span>
                  </span>
                </div>
                <el-date-picker v-model="leaseform.endDate" value-format="yyyy-MM-dd" type="date" placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="signDate" class="slotDatePicker">
                <div class="slot_label">
                  <span slot="label">
                    <br />
                    <span class="slot_span">签订日期&emsp;</span>
                  </span>
                </div>
                <el-date-picker v-model="leaseform.signDate" value-format="yyyy-MM-dd" type="date" placeholder="签订日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="合同状态" prop="contractStatus">
                <el-select v-model="leaseform.contractStatus" placeholder="请选择合同状态" clearable @change="leaseStateChange(leaseform.contractStatus)">
                  <el-option v-for="item in optionsStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <template>
              <el-col :span="8" v-if="leaseform.contractStatus == 2">
                <el-form-item label="案号" prop="caseNo">
                  <el-input v-model="leaseform.caseNo" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </template>
            <template>
              <el-col :span="8" v-if="leaseform.contractStatus == 3 || leaseform.contractStatus == 4">
                <el-form-item label="结束合同日期(实际)" label-width="140px">
                  <el-date-picker v-model="leaseform.stopDate" value-format="yyyy-MM-dd" type="date" placeholder="结束合同日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </template>
            <el-col :span="8">
              <el-form-item label="担保人姓名" prop="guarantor">
                <el-input v-model="leaseform.guarantor" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item :label="'担保人身份证号码'" prop="guarantorIdcardNo" class="wordRol">
                <el-input v-model="leaseform.guarantorIdcardNo" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="'担保人居住地址'" prop="guarantorAddress" class="wordRol">
                <el-input autocomplete="off" v-model="leaseform.guarantorAddress">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="第一期还款日" prop="firstRepaymentDate">
                <el-date-picker :disabled="false" v-model="leaseform.firstRepaymentDate" value-format="yyyy-MM-dd" type="date" placeholder="账单日" style="width:200px">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="担保人电话" prop="guarantorPhone">
                <el-input v-model="leaseform.guarantorPhone" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="销售姓名" prop="seller">
                <el-input autocomplete="off" v-model="leaseform.seller">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="销售者电话" prop="sellerPhone">
                <el-input v-model="leaseform.sellerPhone" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="IRR(%)" prop="irr">
                <el-input autocomplete="off" v-model="leaseform.irr" v-d-input-max="9999999999">
                </el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="保证金(元)" prop="deposit">
                <el-input autocomplete="off" v-model="leaseform.deposit">
                </el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item class="businessForm" label="业务模式" prop="depositInputValue" :rules="depositRules">
                <el-input placeholder="请输入金额" class="input-with-select" v-model="leaseform.depositInputValue">
                  <el-select :disabled="leaseform.renewal == 1" v-model="leaseform.businessModel" slot="prepend" placeholder="请选择" @change="businessModelChange">
                    <el-option label="保证金" :value="1"></el-option>
                    <el-option label="预付三期" :value="2"></el-option>
                    <el-option label="预付一期" :value="3"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="违章担保金" prop="violationDeposit">
                <el-input autocomplete="off" v-model="leaseform.violationDeposit">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="备注" prop="note">
                <el-input type="textarea" v-model="leaseform.note" :autosize="{ minRows: 2, maxRows: 5}"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">

            </el-col>
            <el-col :span="8">

            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="form-dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button v-no-more-click type="primary" @click="SaveLeaseData">确 定</el-button>
        </div>
      </div>
      <!-- 获取车辆信息 -->
      <el-dialog width="50%" title="适用车辆" :visible.sync="innerVisible" append-to-body @close="hiddlenCarDialog">
        <el-row>
          <el-col :span="24">
            <el-row :gutter="20">
              <el-col :span="7">
                <el-input v-model="getCarform.carframeNo" clearable autocomplete="off" width="80%" placeholder="车架号"></el-input>
              </el-col>
              <el-col :span="4">
                <el-input v-model="getCarform.carType" clearable autocomplete="off" width="80%" placeholder="车型"></el-input>
              </el-col>
              <el-col :span="8">
                <el-date-picker style="width: 90%" v-model="getCarform.signingDate" value-format="yyyy-MM-dd" type="date" placeholder="购买时间">
                </el-date-picker>
              </el-col>
              <el-col :span="5">
                <el-button type="primary" size="medium" @click="searchCarList()">查询</el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div style="margin-top: 20px">
              <template>
                <el-table :data="carDataList" style="width: 100%" border class="carShowdialog">
                  <el-table-column prop="carframeNo" label="车架号" width="180">
                  </el-table-column>
                  <el-table-column prop="carType" label="车型">
                  </el-table-column>
                  <el-table-column prop="contractAmount" label="购买金额">
                  </el-table-column>
                  <el-table-column prop="signingDate" label="购买时间">
                  </el-table-column>
                  <el-table-column prop="name" label="操作">
                    <template slot-scope="scope">
                      <el-link type="primary" @click="
                          enterCarId(scope.row.assetsId, scope.row.carframeNo)
                        ">选取</el-link>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </el-col>
        </el-row>
      </el-dialog>

      <!-- 历史版本 -->
      <el-dialog width="50%" :visible.sync="versionVisible" append-to-body>
        <div slot="title">
          <span>历史版本</span>
          <span class="minititle">(仅保留最近20次历史版本)</span>
        </div>
        <el-table :data="leaseVersionData" class="leaseVersionlog">
          <el-table-column property="versionNo" label="版本号 (↑最新版)"></el-table-column>
          <el-table-column property="updateUserName" label="姓名"></el-table-column>
          <el-table-column property="updateDate" label="时间" width="200"></el-table-column>
          <el-table-column property="address" label="操作" width="160">
            <template class="link" slot-scope="scope">
              <el-link class="preLink" :underline="false" type="primary" @click="showInner(scope.row.id, scope.row.versionNo)">预览</el-link>
              <el-link :underline="false" type="primary" @click="setVersionToNew(scope.row.id)">设为最新版</el-link>
            </template>
          </el-table-column>
        </el-table>

        <!-- 预览版本信息 -->
        <el-dialog class="LeaseInfoDialog" width="60%" :title="'信息预览V' + newShowVersion" :visible.sync="singleVersionData" :append-to-body="true" @close="hiddlenMinidislog">
          <el-descriptions title="合同信息">
            <el-descriptions-item label="合同编号">{{
              showInnerData.contractNo
            }}</el-descriptions-item>
            <el-descriptions-item label="适用车辆">{{
              showInnerData.assetsId
            }}</el-descriptions-item>
            <el-descriptions-item label="客户">{{
              showInnerData.customer
            }}</el-descriptions-item>
            <el-descriptions-item label="客户电话">{{
              showInnerData.customerPhone
            }}</el-descriptions-item>
            <el-descriptions-item label="客户备用电话">{{
              showInnerData.customerSparePhone
            }}</el-descriptions-item>
            <el-descriptions-item label="身份证号码">{{
              showInnerData.idcardNo
            }}</el-descriptions-item>
            <el-descriptions-item label="性别">{{
              showInnerData.sex
            }}</el-descriptions-item>
            <el-descriptions-item label="驾驶证到期日">{{
              showInnerData.dreverDueDate
            }}</el-descriptions-item>
            <el-descriptions-item label="月租金(元)">{{
              showInnerData.monthRent
            }}</el-descriptions-item>
            <el-descriptions-item label="租赁期数">{{
              showInnerData.periodsTotal
            }}</el-descriptions-item>
            <el-descriptions-item label="合同金额(元)">{{
              showInnerData.contractAmount
            }}</el-descriptions-item>
            <el-descriptions-item label="其他费用">{{
              showInnerData.otherAmount
            }}</el-descriptions-item>

            <el-descriptions-item label="合同开始日期">{{
              showInnerData.startDate
            }}</el-descriptions-item>
            <el-descriptions-item label="合同结束日期">{{
              showInnerData.endDate
            }}</el-descriptions-item>
            <el-descriptions-item label="	合同签订日期">{{
              showInnerData.signDate
            }}</el-descriptions-item>
            <el-descriptions-item label="	审核状态">{{
              showInnerData.auditState
                ? auditOptionsStatus[showInnerData.auditState].label
                : ""
            }}</el-descriptions-item>
            <el-descriptions-item label="	合同状态">{{
              showInnerData.contractNo
                ? optionsStatus[showInnerData.contractStatus - 1].label
                : ""
            }}</el-descriptions-item>
            <el-descriptions-item label="	案号">{{
              showInnerData.caseNo
            }}</el-descriptions-item>
            <el-descriptions-item label="	结束合同日期(实际)">{{
              showInnerData.stopDate
            }}</el-descriptions-item>
            <el-descriptions-item label="	担保人姓名">{{
              showInnerData.guarantor
            }}</el-descriptions-item>
            <el-descriptions-item label="	担保人身份证号码">{{
              showInnerData.guarantorIdcardNo
            }}</el-descriptions-item>
            <el-descriptions-item label="	担保人居住地址">{{
              showInnerData.guarantorAddress
            }}</el-descriptions-item>
            <el-descriptions-item label="	第一期还款日">{{
              showInnerData.firstRepaymentDate
            }}</el-descriptions-item>
            <el-descriptions-item label="	担保人电话">{{
              showInnerData.guarantorPhone
            }}</el-descriptions-item>
            <el-descriptions-item label="	销售者姓名">{{
              showInnerData.seller
            }}</el-descriptions-item>
            <el-descriptions-item label="	销售者电话">{{
              showInnerData.sellerPhone
            }}</el-descriptions-item>
            <el-descriptions-item label="IRR(%)">{{
              showInnerData.irr
            }}</el-descriptions-item>
            <el-descriptions-item v-if="showInnerData.businessModel == 1" label="保证金(元)">{{
              showInnerData.deposit
            }}</el-descriptions-item>
            <el-descriptions-item v-else-if="showInnerData.businessModel == 2" label="预付三期(元)">{{
              showInnerData.advance
            }}</el-descriptions-item>
            <el-descriptions-item v-else-if="showInnerData.businessModel == 3" label="预付一期(元)">{{
              showInnerData.advance
            }}</el-descriptions-item>
            <el-descriptions-item label="	违章担保金">{{
              showInnerData.violationDeposit
            }}</el-descriptions-item>
            <el-descriptions-item label="备注">{{
              showInnerData.note
            }}</el-descriptions-item>
          </el-descriptions>
        </el-dialog>
      </el-dialog>
    </el-dialog>

    <!-- 查看合同详细信息 -->
    <el-dialog class="addClass" :modal-append-to-body="false" :visible.sync="PreviewVisible" width="75%" @close="PerviewhiddlenDialog">
      <template slot="title">
        <div class="">
          <div style="font-size: 20px; margin-right: 10px;">租赁合同信息</div>
          <div class="contractBox">
            <el-row :gutter="10">
              <el-col :span="6">
                <div class="contractItem">
                  <div class="contractTitle">
                    <i class="el-icon-document"></i>
                    <span>租赁电子合同</span>
                    <template v-if="!isOnlyView">
                      <el-button v-if="lesePreviewData.pdfPath == null" size="mini" plain type="primary" @click="clickaddContract(lesePreviewData.assetsId)">生成合同</el-button>
                      <el-button v-else plain size="mini" type="primary" @click="updateContract">更新合同</el-button>
                    </template>
                  </div>
                  <div class="pdfLink">
                    <el-link :disabled="lesePreviewData.pdfPath ? false : true" :underline="false" type="primary" :class="lesePreviewData.pdfPath ? 'pdfStateY' : 'pdfStateN' " style="font-size:12px;"
                      @click="DownLoadContract">
                      {{lesePreviewData.pdfPath ? lesePreviewData.pdfPath : '&nbsp;' + '— —'}}
                    </el-link>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="contractItem">
                  <div class="contractTitle">
                    <i class="el-icon-document"></i>
                    <span>保证合同</span>
                    <!-- <el-button style="visibility: hidden;" plain size="mini" type="primary"></el-button> -->
                  </div>
                  <div class="pdfLink">
                    <el-link :disabled="lesePreviewData.bzPdfPath ? false : true" :underline="false" type="primary" :class="lesePreviewData.bzPdfPath ? 'pdfStateY' : 'pdfStateN' "
                      style="font-size:12px;" @click="DownLoadBzContractPDF">
                      {{lesePreviewData.bzPdfPath ? lesePreviewData.bzPdfPath : '&nbsp;' + '— —'}}
                    </el-link>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="contractItem">
                  <div class="contractTitle">
                    <i class="el-icon-document"></i>
                    <span>有偿保证合同</span>
                    <!-- <el-button style="visibility: hidden;" plain size="mini" type="primary"></el-button> -->
                  </div>
                  <div class="pdfLink">
                    <el-link :disabled="lesePreviewData.ycbzPdfPath ? false : true" :underline="false" type="primary" :class="lesePreviewData.ycbzPdfPath ? 'pdfStateY' : 'pdfStateN' "
                      style="font-size:12px;" @click="DownLoadycBzContractPDF">
                      {{lesePreviewData.ycbzPdfPath ? lesePreviewData.ycbzPdfPath : '&nbsp;' + '— —'}}
                    </el-link>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="contractItem">
                  <div class="contractTitle">
                    <i class="el-icon-document"></i>
                    <span>二手车买卖合同</span>
                    <template v-if="!isOnlyView">
                      <el-button v-if="lesePreviewData.escmmPdfPath == null" size="mini" plain type="primary" @click="addEscmmPdf()">生成合同</el-button>
                      <el-button v-else plain size="mini" type="primary" @click="updateEscmmPdf">更新合同</el-button>
                    </template>
                  </div>
                  <div class="pdfLink">
                    <el-link :disabled="lesePreviewData.escmmPdfPath ? false : true" :underline="false" type="primary" :class="lesePreviewData.escmmPdfPath ? 'pdfStateY' : 'pdfStateN' "
                      style="font-size:12px;" @click="DownLoadEcmmPdf">
                      {{lesePreviewData.escmmPdfPath ? lesePreviewData.escmmPdfPath : '&nbsp;' + '— —'}}
                    </el-link>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="6">
                <div class="contractItem">
                  <div class="contractTitle">
                    <i class="el-icon-document"></i>
                    <span>还款说明书</span>
                    <template v-if="!isOnlyView">
                      <el-button v-if="lesePreviewData.hksmsPdfPath == null" size="mini" plain type="primary" @click="clickaddHksmsPdfPath()">生成合同</el-button>
                      <el-button v-else plain size="mini" type="primary" @click="updateHksmsPdfPath">更新合同</el-button>
                    </template>
                  </div>
                  <div class="pdfLink">
                    <el-link :disabled="lesePreviewData.hksmsPdfPath ? false : true" :underline="false" type="primary" :class="lesePreviewData.hksmsPdfPath ? 'pdfStateY' : 'pdfStateN' "
                      style="font-size:12px;" @click="DownLoadHksmsPdfPath">
                      {{lesePreviewData.hksmsPdfPath ? lesePreviewData.hksmsPdfPath : '&nbsp;' + '— —'}}
                    </el-link>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="contractItem">
                  <div class="contractTitle">
                    <i class="el-icon-document"></i>
                    <span>付款通知书</span>
                    <template v-if="!isOnlyView">
                      <el-button v-if="lesePreviewData.fktzsPdfPath == null" size="mini" plain type="primary" @click="clickAddPdfPath('fktzsPdf')">生成合同</el-button>
                      <el-button v-else plain size="mini" type="primary" @click="updatePdfPath('fktzsPdf')">更新合同</el-button>
                    </template>
                  </div>
                  <div class="pdfLink">
                    <el-link :disabled="lesePreviewData.fktzsPdfPath ? false : true" :underline="false" type="primary" :class="lesePreviewData.fktzsPdfPath ? 'pdfStateY' : 'pdfStateN' "
                      style="font-size:12px;" @click="DownLoadPdfPath('fktzsPdf')">
                      {{lesePreviewData.fktzsPdfPath ? lesePreviewData.fktzsPdfPath : '&nbsp;' + '— —'}}
                    </el-link>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="contractItem">
                  <div class="contractTitle">
                    <i class="el-icon-document"></i>
                    <span>发票情况说明</span>
                    <template v-if="!isOnlyView">
                      <el-button v-if="lesePreviewData.fpqksmPdfPath == null" size="mini" plain type="primary" @click="clickAddPdfPath('fpqksmPdf')">生成合同</el-button>
                      <el-button v-else plain size="mini" type="primary" @click="updatePdfPath('fpqksmPdf')">更新合同</el-button>
                    </template>
                  </div>
                  <div class="pdfLink">
                    <el-link :disabled="lesePreviewData.fpqksmPdfPath ? false : true" :underline="false" type="primary" :class="lesePreviewData.fpqksmPdfPath ? 'pdfStateY' : 'pdfStateN' "
                      style="font-size:12px;" @click="DownLoadPdfPath('fpqksmPdf')">
                      {{lesePreviewData.fpqksmPdfPath ? lesePreviewData.fpqksmPdfPath : '&nbsp;' + '— —'}}
                    </el-link>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="contractItem">
                  <div class="contractTitle">
                    <i class="el-icon-document"></i>
                    <span>承租人单方承诺函</span>
                    <template v-if="!isOnlyView">
                      <el-button v-if="lesePreviewData.czrdfcnhPdfPath == null" size="mini" plain type="primary" @click="clickAddPdfPath('czrdfcnhPdf')">生成合同</el-button>
                      <el-button v-else plain size="mini" type="primary" @click="updatePdfPath('czrdfcnhPdf')">更新合同</el-button>
                    </template>
                  </div>
                  <div class="pdfLink">
                    <el-link :disabled="lesePreviewData.czrdfcnhPdfPath ? false : true" :underline="false" type="primary" :class="lesePreviewData.czrdfcnhPdfPath ? 'pdfStateY' : 'pdfStateN' "
                      style="font-size:12px;" @click="DownLoadPdfPath('czrdfcnhPdf')">
                      {{lesePreviewData.czrdfcnhPdfPath ? lesePreviewData.czrdfcnhPdfPath : '&nbsp;' + '— —'}}
                    </el-link>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <i class="el-icon-document"></i>
                <el-link :underline="true" type="primary" class='pdfStateY' style="font-size:12px;margin-left: 6px;" @click="DownLoadDqwghsysmsPath">新经营性租赁合同-声明书-关于到期未过户保险事宜-清洁版.pdf</el-link>
              </el-col>
              <el-col :span="12">
              </el-col>
            </el-row>
          </div>
        </div>
      </template>
      <el-descriptions class="margin-top descriptionsBox" :column="3" size="medium" border>
        <el-descriptions-item>
          <template slot="label"> 合同编号 </template>
          {{ lesePreviewData.contractNo }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            适用车辆
            <div>
              <el-link icon="el-icon-info" style="fontsize: 10px; margin: 0px" type="primary" @click="SkipProperty">资产详情</el-link>
            </div>
          </template>
          {{ lesePreviewData.carframeNo }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 客户 </template>
          {{ lesePreviewData.customer }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 客户电话 </template>
          {{ lesePreviewData.customerPhone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 客户备用电话 </template>
          {{ lesePreviewData.customerSparePhone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 身份证号码 </template>
          {{ lesePreviewData.idcardNo }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 性别 </template>
          {{ lesePreviewData.sex }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 驾驶证到期日 </template>
          {{ lesePreviewData.dreverDueDate }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 月租金(元) </template>
          {{ lesePreviewData.monthRent }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 租赁期数 </template>
          {{ lesePreviewData.periodsTotal }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> 合同金额(元) </template>
          {{ lesePreviewData.contractAmount }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 其他费用 </template>
          {{ lesePreviewData.otherAmount }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            起租日期
            <br />
            <el-popover v-model="payPlanvisible" placement="right" width="400" trigger="manual">
              <el-table style="width: 100%" border stripe :header-cell-style="{
                        fontSize: '14px',
                        fontWeight: '600',
                      }" :data="repanmentPlanList" class="dueDateList">
                <el-table-column align="center" width="60" property="periodsTotal" label="期数"></el-table-column>
                <el-table-column align="center" property="repaymentDate">
                  <template slot="header">
                    <div>
                      还款日
                      <i slot="reference" class="el-icon-date" style="color: #66b1ff; font-size: 12px; cursor: pointer" @click="removePayment">修改</i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" property="amount" label="还款金额"></el-table-column>
                <el-table-column align="center" label="状态">
                  <template slot-scope="scope">
                    <el-tag effect="plain" size="small" :type="planStateTag[scope.row.state]">
                      {{ scope.row.stateVal}}
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table>
              <!-- <el-link slot="reference" type="primary" @click="showRepaymentDate">每期计划表</el-link> -->
            </el-popover>
          </template>
          {{ lesePreviewData.startDate }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 结束日期 </template>
          {{ lesePreviewData.endDate }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 签订日期 </template>
          {{ lesePreviewData.signDate }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 担保人姓名 </template>
          {{ lesePreviewData.guarantor }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 担保人身份证号码 </template>
          {{ lesePreviewData.guarantorIdcardNo }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 担保人居住地址 </template>
          {{ lesePreviewData.guarantorAddress }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 第一期还款日 </template>
          {{ lesePreviewData.firstRepaymentDate }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 担保人电话 </template>
          {{ lesePreviewData.guarantorPhone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 销售者姓名 </template>
          {{ lesePreviewData.seller }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 销售者电话 </template>
          {{ lesePreviewData.sellerPhone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 审核状态 </template>
          {{
            lesePreviewData.auditState
              ? auditOptionsStatus[lesePreviewData.auditState].label
              : ""
          }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 合同状态 </template>
          {{
            lesePreviewData.contractStatus
              ? optionsStatus[lesePreviewData.contractStatus - 1].label
              : ""
          }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 案号 </template>
          {{ lesePreviewData.caseNo }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 结束合同日期(实际) </template>
          {{ lesePreviewData.stopDate }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> IRR(%) </template>
          {{ lesePreviewData.irr }}
        </el-descriptions-item>
        <el-descriptions-item v-if="lesePreviewData.businessModel == 1">
          <template slot="label"> 保证金(元) </template>
          {{ lesePreviewData.deposit }}
        </el-descriptions-item>
        <el-descriptions-item v-else-if="lesePreviewData.businessModel == 2">
          <template slot="label"> 预付三期(元)</template>
          {{ lesePreviewData.advance }}
        </el-descriptions-item>
        <el-descriptions-item v-else-if="lesePreviewData.businessModel == 3">
          <template slot="label"> 预付一期(元) </template>
          {{ lesePreviewData.advance }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 违章担保金 </template>
          {{ lesePreviewData.violationDeposit }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 备注 </template>
          {{ lesePreviewData.note }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 还款计划表账单 -->
      <div class="planList">
        <div class="contractTitle" v-if="!isOnlyView">
          <div>
            <i class="el-icon-document"></i>
            <span>还款计划表账单</span>
            <el-button v-if="repanmentPlanList.length === 0" size="mini" plain type="primary" @click="addPaymentPlanList()">生成还款计划表账单</el-button>
          </div>
          <div style="width: 50%;" v-if="repanmentPlanList.length > 0">
            <div class="annotation-inf" v-if="isAnnotation">
              <el-input size="mini" type="textarea" :rows="2" v-model="annotationInput" placeholder="可在此声明修改还款日等信息的原因"></el-input>
              <div class="annotation-btn">
                <el-button size="mini" plain type="primary" @click="saveAnnotation()">保 存</el-button>
                <el-button size="mini" plain type="info" @click="cancelAnnotation()">取 消</el-button>
              </div>
            </div>
            <div class="annotation-inf" v-else>
              <span v-show="lesePreviewData.planAnnotate" class="annotation-text">{{ lesePreviewData.planAnnotate }}</span>
              <div>
                <el-button v-if="!isAnnotation" size="mini" plain type="primary" @click="updateAnnotation()">还款计划表批注</el-button>
              </div>
            </div>
          </div>
        </div>
        <!-- 查看角色--仅保留标题 -->
        <div class="contractTitle" v-else>
          <div>
            <i class="el-icon-document"></i>
            <span>还款计划表账单</span>
          </div>
        </div>
        <div style="margin-bottom: 10px;" v-if="repanmentPlanList.length === 0 && !isOnlyView">
          <el-alert v-if="lesePreviewData.carNumber == null || lesePreviewData.carNumber === ''" title="由于缺失车牌号，无法生成月付账单，请及时到对应资产表补录车牌后，再回来点击生成还款计划表账单按钮" type="error" :closable="false" center>
          </el-alert>
          <el-alert v-else title="可点击生成还款计划表账单按钮，生成月付账单" type="error" :closable="false" center>
          </el-alert>
        </div>
        <el-table v-else style="width: 100%" border stripe :header-cell-style="{ fontSize: '14px',fontWeight: '600', color: 'black ', backgroundColor: '#fafafa'}" :data="repanmentPlanList"
          class="dueDateList">
          <el-table-column align="center" width="60" property="periodsTotal" label="期数"></el-table-column>
          <el-table-column align="center" width="180" property="id" label="订单号"></el-table-column>
          <el-table-column align="center" property="repaymentDate" label="还款日"></el-table-column>
          <el-table-column align="center" property="amount" label="还款金额"></el-table-column>
          <el-table-column align="center" label="出账状态">
            <template slot-scope="scope">
              {{ scope.row.paidout === null ? "--" : (scope.row.paidout === 0 && "未出账") || (scope.row.paidout === 1 && "已出账") || (scope.row.paidout === 2 && "合同已结束")}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否逾期">
            <template slot-scope="scope">
              {{ scope.row.overdue === null ? "--" : scope.row.overdue === 1 ? "已逾期" : "未逾期"}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="付款状态">
            <template slot-scope="scope">
              <el-tag effect="plain" size="small" :type="planStateTag[scope.row.state]">
                {{ scope.row.stateVal}}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="支付时间">
            <template slot-scope="scope">
              {{ scope.row.paymentDate }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="核销账单" v-if="!isOnlyView">
            <template slot-scope="scope">
              <div v-if="scope.row.state == 1 || scope.row.state == 5">
                <el-button :icon="scope.row.writeoffState == 3 ? 'el-icon-folder-delete' : ''" v-if="scope.row.writeoffState == 0 || scope.row.writeoffState == 3" plain type="primary" size="mini"
                  @click="ProvePayment(scope.row)">申请核销</el-button>
                <el-button v-if="scope.row.writeoffState == 1" plain type="warning" size="mini" disabled>处理中</el-button>
              </div>
              <el-tag type="success" v-else-if="scope.row.writeoffState == 2">核销通过</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 添加电子合同表单弹窗 -->
    <el-dialog class="contractDialog" title="电子合同缺失信息补录" :visible.sync="dialogContractVisible" width="70%" append-to-body @close="hiddlenDialogContract" :close-on-click-modal="false">
      <el-form label-position="top" :model="contractform" ref="ruleContForm" :rules="rulesContractform">
        <!-- 甲方公司 -->
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="甲方（公司）抬头" prop="companyName">
              <el-input v-model="contractform.companyName" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="甲方联系地址" prop="companyAddress">
              <el-input autocomplete="off" v-model="contractform.companyAddress" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="甲方联系电话" prop="companyPhone">
              <el-input v-model="contractform.companyPhone" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="甲方收款银行账户" prop="companyBankAccount">
              <el-input v-model="contractform.companyBankAccount" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="甲方收款银行开户行" prop="companyBank">
              <el-input v-model="contractform.companyBank" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
          </el-col>
          <el-col :span="6">
          </el-col>
          <el-col :span="6">
          </el-col>
        </el-row>
        <!-- 乙方公司 -->
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="乙方（承租人）联系地址" prop="tenantAddress">
              <el-input v-model="contractform.tenantAddress" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="乙方还款银行账户" prop="tenantBankAccount">
              <el-input autocomplete="off" v-model="contractform.tenantBankAccount" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="乙方银行开户行" prop="tenantBank">
              <el-input v-model="contractform.tenantBank" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="接收日期（车辆验收确认书）" prop="receivedDate">
              <el-date-picker v-model="contractform.receivedDate" value-format="yyyy-MM-dd" type="date" placeholder="接收日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
          </el-col>
          <el-col :span="6">
          </el-col>
          <el-col :span="6">
          </el-col>
        </el-row>
        <!-- 车辆信息 -->
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="车辆品牌" prop="carBrand">
              <el-input v-model="contractform.carBrand" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规格型号" prop="carSpecification">
              <el-input autocomplete="off" v-model="contractform.carSpecification" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="车辆颜色" prop="carColor">
              <el-input v-model="contractform.carColor" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="车辆数量" prop="carTotal">
              <el-input v-model="contractform.carTotal" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="交付车辆天数（保证金缴纳后）" prop="deliveryDays">
              <el-input v-model="contractform.deliveryDays" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
          </el-col>
          <el-col :span="6">
          </el-col>
          <el-col :span="6">
          </el-col>
        </el-row>
        <!-- 合同信息 -->
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="保证金退回账户" prop="depositRefundAccount">
              <el-input v-model="contractform.depositRefundAccount" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="评估费/公证费（元）" prop="notarizationFee">
              <el-input autocomplete="off" v-model="contractform.notarizationFee" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
          </el-col>
          <el-col :span="6">
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="合同保险公司" prop="insuranceCompany">
              <el-input v-model="contractform.insuranceCompany" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="合同保险额度（万元）" prop="insuranceLimit">
              <el-input v-model="contractform.insuranceLimit" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="行驶里程限制（公里）" prop="mileageLimit">
              <el-input v-model="contractform.mileageLimit" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="支付期次（月/期）" prop="paymentPeriod">
              <el-input v-model="contractform.paymentPeriod" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
          </el-col>
          <el-col :span="6">
          </el-col>
          <el-col :span="6">
          </el-col>
        </el-row>
      </el-form>
      <!-- 保证合同 -->
      <el-row :gutter="20">
        <el-col :span="24">
          <el-divider content-position="left">
            <el-checkbox :disabled="lesePreviewData.bzPdfPath ? true : false" v-model="ensureChecked" @change="ensureCheckedChange">需要保证合同</el-checkbox>
          </el-divider>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24" style="display: flex; align-items: center">
          <div class="YcbzContract">有偿保证合同</div>
          <el-switch v-model="ycBzensureChecked" :disabled="lesePreviewData.ycbzPdfPath ? true : !ensureChecked"></el-switch>
        </el-col>
      </el-row>
      <el-form :disabled="!ensureChecked" label-position="top" :model="ensureform" ref="ruleEnsureForm" :rules="rulesEnsureform">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item class="guaranteeStyle" label="选择保证人" prop="guaranteeTypeArr">
              <el-checkbox-group v-model="ensureform.guaranteeTypeArr" @change="guaranteeTypeArrChange">
                <el-checkbox :label="'company'" name="guaranteeTypeArr">企业</el-checkbox>
                <el-checkbox :label="'person'" name="guaranteeTypeArr">个人</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 保证公司 -->
        <div v-if="ensureform.guaranteeTypeArr.includes('company')">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="担保公司名称" prop="guaranteeCompany">
                <el-input v-model="ensureform.guaranteeCompany" autocomplete="off" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="担保公司注册地址" prop="guaranteeCompanyRegisteredAddress">
                <el-input autocomplete="off" v-model="ensureform.guaranteeCompanyRegisteredAddress" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="主要营业地址" prop="guaranteeCompanyBusinessAddress">
                <el-input v-model="ensureform.guaranteeCompanyBusinessAddress" autocomplete="off" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="邮编" prop="guaranteeCompanyPostalCode">
                <el-input v-model="ensureform.guaranteeCompanyPostalCode" autocomplete="off" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="法定代表人姓名" prop="legalPerson">
                <el-input v-model="ensureform.legalPerson" autocomplete="off" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="法人身份证号码" prop="legalPersonIdCard">
                <el-input autocomplete="off" v-model="ensureform.legalPersonIdCard" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="法人住所" prop="legalPersonResidence">
                <el-input v-model="ensureform.legalPersonResidence" autocomplete="off" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="法人电话" prop="legalPersonTelephone">
                <el-input placeholder="XXX-XXXXXXX" v-model="ensureform.legalPersonTelephone" autocomplete="off" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="法人手机号" prop="legalPersonPhone">
                <el-input v-model="ensureform.legalPersonPhone" autocomplete="off" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
            </el-col>
            <el-col :span="6">
            </el-col>
            <el-col :span="6">
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogContractVisible = false">取 消</el-button>
        <el-button v-no-more-click type="primary" @click="SaveContractData">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 第一期还款日 #弃用 -->
    <!-- <el-dialog top="30vh" :show-close="false" :visible.sync="repmentDateVisible" width="300px" center @close="repmentDateHide" :close-on-click-modal="false">
      <div style="display: flex;justify-content: center">
        <el-date-picker v-model="firstRepaymentDate" value-format="yyyy-MM-dd" type="date" placeholder="第一期还款日">
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="repmentDateVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="setRepaymentDate">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import {
  cityList,
  companyList,
  leaseData,
  downContract,
  addleaseData,
  updateleaseData,
  propertyData,
  getidleaseData,
  getcontractHistory,
  getcontractSingle,
  updateIdNew,
  deleteLeaseDate,
  FixedAssetsById,
} from "@/api/index.js";

import {
  genBzContractPDF,
  getBzContractSupplemenById,
  updateBzContractPDF,
  downloadBzContractPDF,
  genEscmmContractPDF,
  updateEscmmContractPDF,
  downloadEscmmContractPDF,
  genHksmsPDF,
  updateHksmstPDF,
  downloadHksmsPDF,
  genYcbzContractPDF,
  updateYcbzContractPDF,
  downloadYcbzContractPDF,
  genFktzsPDF,
  updateFktzsPDF,
  downloadFktzsPDF,
  genFpqksmPDF,
  updateFpqksmPDF,
  downloadFpqksmPDF,
  genCzrdfcnhPDF,
  updateCzrdfcnhPDF,
  downloadCzrdfcnhPDF,
  downloadDqwghsysmsPDF,
} from "@/api/leasePdf.js";

import {
  addContract,
  getRepanmentPlanList,
  updateRepaymentDate,
  getContract,
  updateContractPDF,
  downContractPDF,
  getContractHeaderInf,
  addRepanmentPlanList,
  setRepanmentPlanAnnotate,
} from "@/api/contractpdf.js";
import { requestWriteoff } from "@/api/provePayment.js";
import { Loading } from "element-ui";
import _ from "lodash";

import { auditOptionsStatus } from "@/plugins/commonData.js";
import { computerDate, formatPdfPath } from "@/plugins/commonMethods.js";
export default {
  name: "lease",
  data() {
    return {
      leaseListData: [],

      queryFrom: {
        contractNo: "",
        search: "",
        signDate: "",
        contractStatus: "",
        auditState: "",
        cityId: "",
        companyId: "",
        startDateStr: "",

        assetsId: "", // 车辆id~合同
        expirationReminderSort: "", // 到期日排序
        expiresFlag: false, //即将到期合同(30天)
        notPlanFlag: false, //有无生成还款计划表
      },

      optionsCity: [],
      optionsCompany: [],
      optionsStatus: [
        { value: 1, label: "存续" },
        { value: 2, label: "起诉中" },
        { value: 3, label: "已结束" },
        { value: 4, label: "已结束(36)" },
      ],
      tagsStyle: ["", "warning", "danger", "success"],
      auditOptionsStatus,

      pagenum: 1, // 页码
      size: 10, // 单页数据数
      total: 10, // 数据总数

      /** 新增表单 */
      leaseform: {
        contractNo: "", // 合同编号
        assetsId: "", //	资产id
        customer: "", // 客户--客户姓名
        customerPhone: "", // 客户电话
        idcardNo: "", // 身份证号码
        sex: "", // 性别
        customerSparePhone: "", //客户备用电话

        dreverDueDate: "", // 驾驶证到期日
        contractAmount: "", // 合同金额
        periodsTotal: "12", // 租赁期数
        otherAmount: "", //其他费用
        startDate: "", // 合同开始日期--起租日期
        endDate: "", // 合同结束日期
        signDate: "", // 合同签订日期

        contractStatus: 1, // 合同状态
        caseNo: "", // 案号
        guarantor: "", // 担保人
        guarantorPhone: "", // 担保电话
        seller: "", // 销售者--姓名
        sellerPhone: "", // 销售电话
        irr: "", // IRR(%)
        businessModel: 1, // 业务模式
        deposit: "", // 保证金(元):1
        advance: "", // 预付租金(元)---预付三/一期:2 3
        monthRent: "", // 月租金

        carframeNo: "", // 车架号
        note: "", //合同备注

        transferFlag: 0, //是否转移资产新增（1：是，0：不是）
        depositInputValue: "", // 业务模式组件绑定的值

        guarantorIdcardNo: "", // 担保人身份证号码
        guarantorAddress: "", // 担保人地址

        firstRepaymentDate: "", // 第一期还款日

        violationDeposit: "", // 违章担保金

        stopDate: "", // 结束合同日期(实际)
      },

      isOnceperiodsTotal: false,
      rulesLeaseform: {
        contractNo: [
          { required: true, message: "请输入合同编号", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "合同编号长度在1到50个字符",
            trigger: "blur",
          },
          {
            message: "输入框包含除字母、数字、汉字、-连字符之外的内容",
            pattern: /^[\w\d\-—\u4e00-\u9fa5]+$/,
            trigger: "blur",
          },
        ],
        carframeNo: [{ required: true, message: "请选择适用车辆", trigger: "blur,change" }],
        customer: [
          { required: true, message: "请输入客户名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "客户名称长度在1到20个字符",
            trigger: "blur",
          },
        ],
        customerPhone: [
          { required: true, message: "请输入客户电话", trigger: "blur" },
          {
            message: "手机号格式错误",
            pattern: /^1[3-9]\d{9}$/,
            trigger: "blur",
          },
        ],
        customerSparePhone: [
          {
            message: "手机号格式错误",
            pattern: /^1[3-9]\d{9}$/,
            trigger: "blur",
          },
        ],
        idcardNo: [
          {
            required: true,
            message: "请输入客户身份证号",
            trigger: "blur",
          },
          {
            message: "身份证号格式错误(18位)",
            pattern: /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$/,
            trigger: "blur",
          },
        ],
        contractAmount: [
          { required: true, message: "请输入合同金额", trigger: "blur" },
          {
            message: "合同金额不能超过10位正整数",
            pattern: /^\d{1,10}$/,
            trigger: "blur",
          },
        ],
        periodsTotal: [
          { required: true, message: "请输入租赁期数", trigger: "blur" },
          {
            message: "租赁期数不能超过3位正整数",
            pattern: /^\d{1,3}$/,
            trigger: "blur",
          },
        ],
        startDate: [{ required: true, message: "请选择起租日期", trigger: "blur" }],
        endDate: [{ required: true, message: "请选择结束日期", trigger: "blur" }],
        signDate: [{ required: true, message: "请选择签订日期", trigger: "blur" }],
        contractStatus: [{ required: true, message: "请选择合同状态", trigger: "blur" }],
        caseNo: [
          {
            min: 1,
            max: 50,
            message: "案号长度不得超过50个字符",
            trigger: "blur",
          },
        ],
        guarantor: [
          {
            min: 1,
            max: 20,
            message: "担保人姓名长度不得超过20个字符",
            trigger: "blur",
          },
        ],
        seller: [
          { required: true, message: "请输入销售姓名", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "销售姓名长度不得超过20个字符",
            trigger: "blur",
          },
        ],
        sellerPhone: [
          {
            message: "手机号格式错误",
            pattern: /^1[3-9]\d{9}$/,
            trigger: "blur",
          },
        ],
        guarantorIdcardNo: [
          {
            message: "身份证号格式错误(18位)",
            pattern: /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$/,
            trigger: "blur",
          },
        ],
        guarantorAddress: [
          {
            min: 1,
            max: 50,
            message: "地址长度不得超过50个字符",
            trigger: "blur",
          },
        ],
        guarantorPhone: [
          {
            message: "手机号格式错误",
            pattern: /^1[3-9]\d{9}$/,
            trigger: "blur",
          },
        ],
        monthRent: [
          { required: true, message: "请填写月租金", trigger: "blur" },
          {
            message: "月租金不能超过10位正整数",
            pattern: /^\d{1,10}$/,
            trigger: "blur",
          },
        ],
        irr: [
          {
            message: "月租金必须是数字类型",
            pattern: /^(-|\+)?\d+(\.\d+)?$/,
            trigger: "blur",
          },
        ],
        otherAmount: [
          {
            message: "其他费用不能超过7位正整数",
            pattern: /^\d{1,7}$/,
            trigger: "blur",
          },
        ],
        note: [
          {
            min: 0,
            max: 500,
            message: "备注信息长度在0到500个字符",
            trigger: "blur",
          },
        ],
        firstRepaymentDate: [{ required: true, message: "请勾选第一期还款日（账单日）", trigger: "blur" }],
        violationDeposit: [{ message: "违章担保金不能超过10位正整数", pattern: /^\d{1,10}$/, trigger: "blur" }],
      },
      Copyleaseform: {}, //备份leasefrom

      dueDateList: [], // 还款日列表
      residueDate: "0", // 当前剩余时间

      /** 查询车辆表单 */
      getCarform: {
        carframeNo: "",
        signingDate: "",
        carType: "",
      },
      carDataList: [],
      carsize: 100,
      carpagenum: 1,

      /** 合同历史版本列表 */
      leaseVersionData: [],
      newShowVersion: "", // 当前版本号
      showInnerData: [], // 弹窗预览详细信息

      dialogFormVisible: false, // 外部表单弹窗
      innerVisible: false, // 获取车辆信息弹窗
      versionVisible: false, // 历史版本弹窗
      singleVersionData: false, // 预览历史版本合同信息
      leaseId: -1, // 记录需要修改合同id
      leaseVersion: "",

      PreviewVisible: false, //
      lesePreviewData: {}, // 查看弹窗信息

      isRenew: false, //续签状态

      dialogContractVisible: false, // 电子合同弹窗
      contractform: {
        companyName: "", // 甲方抬头
        companyAddress: "", //甲方联系地址
        companyPhone: "", //甲方联系电话
        companyBankAccount: "", //甲方收款银行账户
        companyBank: "", //甲方收款银行开户行

        tenantAddress: "", // 乙方（承租人）联系地址
        tenantBankAccount: "", //乙方还款银行账户
        tenantBank: "", //乙方银行开户行
        receivedDate: "", // 接收日期

        carBrand: "", //车辆品牌
        carSpecification: "", //规格型号
        carColor: "", //车辆颜色
        carTotal: "1", // 车辆数量
        deliveryDays: "十", //交付车辆天数（保证金缴纳后）

        depositRefundAccount: "乙方", //保证金退回账户
        notarizationFee: "2460", //评估费/公证费
        insuranceCompany: "平安或中国人保或中国人寿等", //合同保险公司
        insuranceLimit: "150", //合同保险额度
        mileageLimit: "3000", //行驶里程限制

        paymentPeriod: "1", //支付期次
      },
      initialcontractform: {}, //备份初始电子合同
      rulesContractform: {
        companyName: [
          { required: true, message: "请输入甲方（公司）抬头", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "甲方（公司）抬头长度在1到50个字符",
            trigger: "blur",
          },
        ],
        companyAddress: [
          { required: true, message: "请输入甲方联系地址", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "甲方联系地址长度在1到50个字符",
            trigger: "blur",
          },
        ],
        companyPhone: [
          { required: true, message: "请输入甲方联系电话", trigger: "blur" },
          {
            message: "手机号格式错误",
            pattern: /^1[3-9]\d{9}$/,
            trigger: "blur",
          },
        ],
        companyBankAccount: [
          { required: true, message: "请输入甲方收款银行账户", trigger: "blur" },
          {
            message: "账户格式不正确(范围应在8~21位数字)",
            pattern: /^\d{8,21}$/,
            trigger: "blur",
          },
        ],
        companyBank: [
          { required: true, message: "请输入甲方收款银行开户行", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "甲方收款银行开户行长度在1到50个字符",
            trigger: "blur",
          },
        ],
        tenantAddress: [
          { required: true, message: "请输入乙方（承租人）联系地址", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "联系地址长度在1到50个字符",
            trigger: "blur",
          },
        ],
        tenantBankAccount: [
          // { required: true, message: "请输入乙方还款银行账户", trigger: "blur" },
          {
            message: "账户格式不正确(范围应在8~21位数字)",
            pattern: /^\d{8,21}$/,
            trigger: "blur",
          },
        ],
        tenantBank: [
          // { required: true, message: "请输入乙方银行开户行", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "乙方银行开户行长度在1到50个字符",
            trigger: "blur",
          },
        ],
        carBrand: [
          { required: true, message: "请输入车辆品牌", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "车辆品牌长度在1到50个字符",
            trigger: "blur",
          },
        ],
        carSpecification: [
          { required: true, message: "请输入规格型号", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "规格型号长度在1到50个字符",
            trigger: "blur",
          },
        ],
        carColor: [
          { required: true, message: "请输入车辆颜色", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "车辆颜色长度在1到20个字符",
            trigger: "blur",
          },
        ],
        carTotal: [
          { required: true, message: "请输入车辆数量", trigger: "blur" },
          {
            message: "车辆数量范围应在1~99",
            pattern: /^[1-9][0-9]?$|^99$/,
            trigger: "blur",
          },
        ],
        deliveryDays: [
          { required: true, message: "请输入交付车辆天数", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "交付车辆天数长度在1到20个字符",
            trigger: "blur",
          },
        ],
        depositRefundAccount: [
          { required: true, message: "请输入保证金退回账户", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "保证金退回账户长度在1到20个字符",
            trigger: "blur",
          },
        ],
        notarizationFee: [
          { required: true, message: "请输入评估费/公证费", trigger: "blur" },
          {
            message: "评估费/公证费范围应在0.01~999999.99",
            pattern: /^(?!0$|1000000$)(?:\d{1,6}(?:\.\d{1,2})?)$/,
            trigger: "blur",
          },
        ],
        insuranceCompany: [
          { required: true, message: "请输入合同保险公司", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "保险公司长度在1到50个字符",
            trigger: "blur",
          },
        ],
        insuranceLimit: [
          { required: true, message: "请输入合同保险额度", trigger: "blur" },
          {
            message: "合同保险额度范围应在1~9999",
            pattern: /^[1-9][0-9]{0,3}$/,
            trigger: "blur",
          },
        ],
        mileageLimit: [
          { required: true, message: "请输入行驶里程限制", trigger: "blur" },
          {
            message: "行驶里程限制范围应在1~99999",
            pattern: /^[1-9][0-9]{0,4}$/,
            trigger: "blur",
          },
        ],
        paymentPeriod: [
          { required: true, message: "请输入支付期次", trigger: "blur" },
          {
            message: "支付期次范围应在1~99",
            pattern: /^[1-9][0-9]?$|^99$/,
            trigger: "blur",
          },
        ],
      },
      payPlanvisible: false, //还款计划气泡
      repanmentPlanList: [], //还款计划表~
      planStateTag: ["", "info", "", "warning", "success", ""],
      repmentDateVisible: false, // 修改还款日期弹窗
      firstRepaymentDate: "", // 第一期还款日 #INFO 弃用~
      leasePaymentState: -1, // -1 未开始还款 1开始还款~
      leaseFormPaymentState: false, //校验合同表单 禁止修改--还款日

      //保证合同表单
      ensureChecked: false, //是否生成保证合同
      ycBzensureChecked: false, //是否生成有偿保证合同
      ensureform: {
        guaranteeTypeArr: [], // 选择保证人
        guaranteeCompany: "", //担保公司名称
        guaranteeCompanyRegisteredAddress: "", //担保公司注册地址
        guaranteeCompanyBusinessAddress: "", //主要营业地址
        guaranteeCompanyPostalCode: "", // 邮编
        legalPerson: "", // 法定代表人姓名
        legalPersonIdCard: "", // 法定代表人身份证号
        legalPersonResidence: "", // 法人住所
        legalPersonTelephone: "", // 固定电话
        legalPersonPhone: "", // 法人手机号
      },
      rulesEnsureform: {
        guaranteeTypeArr: [{ type: "array", required: true, message: "请至少选择一项", trigger: "change" }],
        guaranteeCompany: [
          { required: true, message: "请输入担保公司名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "担保公司名称长度在1到50个字符",
            trigger: "blur",
          },
        ],
        guaranteeCompanyRegisteredAddress: [
          { required: true, message: "请输入担保公司注册地址", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "担保公司注册地址长度在1到50个字符",
            trigger: "blur",
          },
        ],
        guaranteeCompanyBusinessAddress: [
          { required: true, message: "请输入主要营业地址", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "主要营业地址长度在1到50个字符",
            trigger: "blur",
          },
        ],
        guaranteeCompanyPostalCode: [
          { required: true, message: "请输入邮编", trigger: "blur" },
          {
            message: "邮编格式错误",
            pattern: /^\d{6}$/,
            trigger: "blur",
          },
        ],
        legalPerson: [
          { required: true, message: "请输入法定代表人姓名", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "法定代表人姓名长度在1到20个字符",
            trigger: "blur",
          },
        ],
        legalPersonIdCard: [
          {
            required: true,
            message: "请输入法人身份证号码",
            trigger: "blur",
          },
          {
            message: "身份证号格式错误(18位)",
            pattern: /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$/,
            trigger: "blur",
          },
        ],
        legalPersonResidence: [
          { required: true, message: "请输入法人住所", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "法人住所长度在1到20个字符",
            trigger: "blur",
          },
        ],
        legalPersonTelephone: [
          { required: true, message: "请输入法人电话", trigger: "blur" },
          {
            message: "法人电话格式错误",
            pattern: /^\d{3,4}\-\d{7,8}$/,
            trigger: "blur",
          },
        ],
        legalPersonPhone: [
          { required: true, message: "请输入法人手机号", trigger: "blur" },
          {
            message: "法人手机号格式错误",
            pattern: /^1[3-9]\d{9}$/,
            trigger: "blur",
          },
        ],
      },
      annotationInput: "", // 还款计划表批注
      isAnnotation: false, // 是否更新批注
      formLoading: false, // 合同表单更新加载~
    };
  },
  computed: {
    // 动态遍历需要传的查询参数
    queryFromData() {
      const date = {};
      for (const key in this.queryFrom) {
        // console.log(this.queryFrom[key]);
        if (this.queryFrom[key] !== "" && this.queryFrom[key] !== false) {
          date[key] = this.queryFrom[key];
          if (date[key] === true) {
            date[key] = 1;
          }
        }
      }
      return date;
    },

    // 是否只有查看权限
    isOnlyView() {
      return this.$store.getters.getOnlyViewRole;
    },

    depositRules() {
      const rules = [];
      if (this.leaseform.businessModel == "") {
        rules.push({ message: "请选择业务模式", required: true, trigger: "blur" });
      }
      if (this.leaseform.businessModel == 1) {
        rules.push({ required: true, message: "保证金不能为空", trigger: "blur" });
        rules.push({ message: "保证金不能超过10位正整数", pattern: /^\d{1,10}$/, trigger: "blur" });
      }
      if (this.leaseform.businessModel == 2 || this.leaseform.businessModel == 3) {
        rules.push({ message: "预付租金不能超过10位正整数", pattern: /^\d{1,10}$/, trigger: "blur" });
      }

      return rules;
    },
  },
  created() {
    this.getcityList();
    this.getLeaseData();
  },
  activated() {
    console.log(this.$route.params.id);
    let id = this.$route.params.id;
    if (id && id > 0) {
      this.ReadLese(id);
      id = -1;
    }

    //资产保存成功直接跳转合同新增表单
    console.log("资产" + this.$route.params.assetsId, this.$route.params.carframeNo);
    if (this.$route.params.assetsId && this.$route.params.carframeNo) {
      this.leaseform.assetsId = this.$route.params.assetsId;
      this.leaseform.carframeNo = this.$route.params.carframeNo;
      this.addCarItem(); //点击新建合同按钮
    }
    if (this.$route.params.replace) this.leaseform.transferFlag = 1;
  },
  watch: {
    // 监听时间组件清空值为null
    "queryFrom.signDate"(newVal) {
      if (newVal == null) {
        this.queryFrom.signDate = "";
      }
    },
    // 监听时间组件清空值为null
    "queryFrom.startDateStr"(newVal) {
      if (newVal == null) {
        this.queryFrom.startDateStr = "";
      }
    },
    // 监听城市选项是否为空
    "queryFrom.cityId"(newVal) {
      if (newVal == "") {
        this.optionsCompany = [];
        this.queryFrom.companyId = "";
      } else {
        // 获取公司列表
        this.getcompanyList();
        this.queryFrom.companyId = "";
      }
    },
    // 监听时间组件清空值为null
    "leaseform.dreverDueDate"(newVal) {
      if (newVal == null) {
        this.leaseform.dreverDueDate = "";
      }
    },
    "leaseform.startDate"(newVal) {
      if (newVal == null) {
        this.leaseform.startDate = "";
      }
    },
    "leaseform.endDate"(newVal) {
      if (newVal == null) {
        this.leaseform.endDate = "";
      }
    },
    "leaseform.signDate"(newVal) {
      if (newVal == null) {
        this.leaseform.signDate = "";
      }
    },
    "getCarform.signingDate"(newVal) {
      if (newVal == null) {
        this.getCarform.signingDate = "";
      }
    },
    "contractform.receivedDate"(newVal) {
      if (newVal == null) {
        this.contractform.receivedDate = "";
      }
    },
  },
  methods: {
    getLeaseData(refresh) {
      leaseData(this.queryFromData, this.pagenum, this.size).then((res) => {
        console.log(res);

        // 二次过滤,提醒日期相同,依据签订日期升降序
        const DateList = res.data.dataList;
        // 1.配置数据转化时间戳--剩余天数
        DateList.forEach((ele) => {
          ele.signTimestamp = this.$moment(ele.signDate).valueOf();
          ele.residueTime = this.computerDate(ele.endDate) + "";
          ele.irr = this.irrFormatt(ele.irr);

          // 分割合同金额
          const contractAmount = ele.contractAmount;
          ele.contractAmount = contractAmount != null ? Number(contractAmount).toLocaleString() : "";
          // 分割月租金
          const monthRent = ele.monthRent;
          ele.monthRent = monthRent != null ? Number(monthRent).toLocaleString() : "";
        });

        // // 升序
        // if (this.queryFromData.expirationReminderSort == "asc") {
        //   DateList.sort(function (a, b) {
        //     if (a.residueTime != b.residueTime) {
        //       return a.residueTime - b.residueTime;
        //     }
        //     return a.signTimestamp - b.signTimestamp;
        //   });
        //   console.log(DateList);
        // } else if (this.queryFromData.expirationReminderSort == "desc") {
        //   // 降序
        //   DateList.sort(function (b, a) {
        //     if (a.residueTime != b.residueTime) {
        //       return a.residueTime - b.residueTime;
        //     }
        //     return a.signTimestamp - b.signTimestamp;
        //   });
        // }

        console.log(DateList);
        this.leaseListData = DateList;
        this.total = res.data.total;
        if (refresh == "refresh") this.$message.success("刷新成功");
      });
    },
    refresh() {
      this.getLeaseData("refresh");
    },
    getcityList() {
      cityList().then((res) => {
        this.optionsCity = res.data.dataList;
        window.sessionStorage.setItem("cityList", JSON.stringify(this.optionsCity));
      });
    },
    getcompanyList() {
      companyList(this.queryFrom.cityId).then((res) => {
        console.log(res);
        this.optionsCompany = res.data.dataList;
      });
    },

    // 监听pagesize改变的事件
    handleSizeChange(newSize) {
      console.log(newSize);
      this.size = newSize;
      this.pagenum = 1;
      this.getLeaseData(); // 发起数据请求
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.getLeaseData(); // 发起数据请求
    },
    // 模糊搜索lease
    searchList() {
      // if (Object.keys(this.queryFromData).length === 0) {
      //   this.$message.warning("请输入需要查询的条件!!");
      //   return;
      // }
      this.pagenum = 1;
      this.size = 10;
      this.getLeaseData();
    },
    // 新增合同表单按钮
    addCarItem() {
      //租赁期数 12
      this.leaseform.periodsTotal = 12;
      this.leaseform.contractStatus = 1;
      this.isOnceperiodsTotal = true;
      this.dialogFormVisible = true;
      this.leaseform.businessModel = 1;
    },

    //计算合同金额---月租金  (处理异常数据计算periodsTotal)
    changeMonthRent(val) {
      if (val == "") {
        this.leaseform.contractAmount = "";
        return;
      }
      if (this.leaseform.businessModel == 2) {
        this.leaseform.depositInputValue = val * 3;
      } else if (this.leaseform.businessModel == 3) {
        this.leaseform.depositInputValue = val;
      }

      console.log(val, this.leaseform.otherAmount == "");
      let periodsTotal = Number(this.leaseform.periodsTotal);
      let otherAmount = Number(this.leaseform.otherAmount == "" ? 0 : this.leaseform.otherAmount);
      console.log(periodsTotal, otherAmount);

      if (!periodsTotal) return;

      let contractAmount = Number(val) * periodsTotal + otherAmount;
      console.log(contractAmount);
      if (contractAmount) this.leaseform.contractAmount = contractAmount;
    },
    //计算合同金额---期数
    changePeriodsTotal(val) {
      this.isOnceperiodsTotal = false;
      if (val == "") {
        this.leaseform.contractAmount = "";
        return;
      }
      console.log(val);
      let monthRent = Number(this.leaseform.monthRent);
      let otherAmount = Number(this.leaseform.otherAmount == "" ? 0 : this.leaseform.otherAmount);

      if (!monthRent) return;

      let contractAmount = Number(val) * monthRent + otherAmount;
      console.log(contractAmount);
      if (contractAmount) this.leaseform.contractAmount = contractAmount;
    },
    //计算合同金额---其他费用
    changeOtherAmount(val) {
      if (this.leaseform.monthRent == "" || this.leaseform.periodsTotal == "") {
        return;
      }
      console.log(val);
      let monthRent = Number(this.leaseform.monthRent);
      let periodsTotal = Number(this.leaseform.periodsTotal);

      let contractAmount = Number(val) + monthRent * periodsTotal;
      console.log(contractAmount);
      if (contractAmount) this.leaseform.contractAmount = contractAmount;
    },
    // 计算过期时间
    computerDate,
    updatedBusinessParam() {
      if (this.leaseform.businessModel == 1) {
        this.leaseform.deposit = this.leaseform.depositInputValue;
        this.leaseform.advance = "";
      }
      if (this.leaseform.businessModel == 2 || this.leaseform.businessModel == 3) {
        this.leaseform.advance = this.leaseform.depositInputValue;
        this.leaseform.deposit = "";
      }
    },
    //回显组件数据
    upadateComponentBusiness() {
      if (this.leaseform.businessModel == 1) {
        this.leaseform.depositInputValue = this.leaseform.deposit;
      }
      if (this.leaseform.businessModel == 2 || this.leaseform.businessModel == 3) {
        this.leaseform.depositInputValue = this.leaseform.advance;
      }
    },
    // 确认表单按钮
    SaveLeaseData() {
      //编辑
      if (this.leaseId > 0 && this.isRenew == false) {
        console.log("编辑");
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.formLoading = true;
            this.leaseform.irr = this.irrFormatt(this.leaseform.irr);
            this.updatedBusinessParam();
            updateleaseData(this.leaseform, this.leaseId)
              .then((res) => {
                console.log(res);
                this.dialogFormVisible = false;
                //this.pagenum = 1;
                // this.size = 10;
                this.getLeaseData();
                this.$message.success("编辑成功");
                this.$notify.closeAll(); // 隐藏错误提示信息
              })
              .catch((err) => {
                console.log(err);
                this.formLoading = false;
                const arr = [];
                if (err.code == 10001) {
                  for (const key in err.data) {
                    arr.push("● " + err.data[key]);
                  }
                  const str = arr.join(" <br/> ");
                  this.$notify.error({
                    dangerouslyUseHTMLString: true,
                    title: "错误",
                    message: str,
                    position: "top-right",
                    duration: 5000,
                  });
                }
              });
          }
        });
      } else if (this.leaseId < 0 && this.isRenew == false) {
        //新建
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.formLoading = true;
            this.leaseform.irr = this.irrFormatt(this.leaseform.irr);
            this.updatedBusinessParam();
            addleaseData(this.leaseform)
              .then((res) => {
                console.log(res);
                this.dialogFormVisible = false;
                this.pagenum = 1;
                this.size = 10;
                this.getLeaseData();
                this.$message.success("新合同生成成功");
                this.$notify.closeAll(); // 隐藏错误提示信息
              })
              .catch((err) => {
                console.log(err);
                this.formLoading = false;
                const arr = [];
                if (err.code == 10001) {
                  for (const key in err.data) {
                    arr.push("● " + err.data[key]);
                  }
                  const str = arr.join(" <br/> ");
                  this.$notify.error({
                    dangerouslyUseHTMLString: true,
                    title: "错误",
                    message: str,
                    position: "top-right",
                    duration: 5000,
                  });
                }
              });
          }
        });
      } else if (this.leaseId > 0 && this.isRenew == true) {
        //续签
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.formLoading = true;
            //1,修改旧合同为已结束,
            this.Copyleaseform.contractStatus = 3;
            updateleaseData(this.Copyleaseform, this.leaseId)
              .then((ress) => {
                console.log(ress);
                this.$notify.closeAll(); // 隐藏错误提示信息
                if (ress.code == 10000) {
                  //新建--续签新合同
                  this.leaseform.irr = this.irrFormatt(this.leaseform.irr);
                  this.updatedBusinessParam();
                  addleaseData(this.leaseform)
                    .then((res) => {
                      console.log(res);
                      this.dialogFormVisible = false;
                      this.pagenum = 1;
                      this.size = 10;
                      this.getLeaseData();
                      this.$message.success("新合同生成成功");
                      this.$notify.closeAll(); // 隐藏错误提示信息
                    })
                    .catch((err) => {
                      console.log(err);
                      this.formLoading = false;
                      const arr = [];
                      if (err.code == 10001) {
                        for (const key in err.data) {
                          arr.push("● " + err.data[key]);
                        }
                        const str = arr.join(" <br/> ");
                        this.$notify.error({
                          dangerouslyUseHTMLString: true,
                          title: "错误",
                          message: str,
                          position: "top-right",
                          duration: 5000,
                        });
                      }
                    });
                }
              })
              .catch((err) => {
                console.log(err);
                this.formLoading = false;
                const arr = [];
                if (err.code == 10001) {
                  for (const key in err.data) {
                    arr.push("● " + err.data[key]);
                  }
                  const str = arr.join(" <br/> ");
                  this.$notify.error({
                    dangerouslyUseHTMLString: true,
                    title: "错误",
                    message: str,
                    position: "top-right",
                    duration: 5000,
                  });
                }
              });
          }
        });
      }
    },

    // 保留两位小数-补零
    irrFormatt(num) {
      // console.log(num);
      if (num === 0) return "0";
      if (num == null || num == "") return "";
      // var value = Math.floor(parseFloat(num) * 100) / 100;
      // var s = value.toString().split(".");
      // if (s.length == 1) {
      //   value = value.toString() + ".00";
      //   return value;
      // }
      // if (s.length > 1) {
      //   if (s[1].length < 2) {
      //     value = value.toString() + "0";
      //   }
      //   return value;
      // }
      let len = 2; //保留几位小数
      num = num.toString();
      let index = num.indexOf(".");
      if (index !== -1) {
        num = num.substring(0, index + 1 + len);
      } else {
        num = num.substring(0);
      }
      return parseFloat(num).toFixed(len);
    },
    //根据身份证自动计算--用户性别
    idcardNoChange(idcardNo) {
      console.log(idcardNo);
      var regExp = new RegExp(/^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$/);
      if (regExp.test(idcardNo)) {
        var num = idcardNo.charAt(16); //身份证号合法取第17位
        if (num % 2 == 0) {
          this.leaseform.sex = "女";
        } else {
          this.leaseform.sex = "男";
        }
      } else {
        this.leaseform.sex = "";
      }
    },

    // 点击编辑按钮---回显数据
    editCarinfo(id) {
      console.log(id);
      this.leaseId = id;
      getidleaseData(id).then((res) => {
        console.log(res);
        res.data.irr = this.irrFormatt(res.data.irr);

        // 重置表单数据
        for (const key in this.leaseform) {
          // console.log(res.data[key]);
          this.leaseform[key] = res.data[key];
        }
        this.upadateComponentBusiness();
        this.leaseform.renewal = res.data.renewal;
        this.leaseVersion = res.data.versionNo;
        this.dialogFormVisible = true;
      });
      const params = { contractId: id };
      getRepanmentPlanList(params).then((res) => {
        console.log(res);
        const list = res.data;
        const reultState = list.some((item) => item.state == 2);
        console.log(reultState);
        this.leaseFormPaymentState = reultState;
      });
    },

    // 关闭表单弹窗 重置表单中的数据
    hiddlenDialog() {
      console.log("重置");
      this.$refs.ruleForm.resetFields(); /// /添加表单验证--重置数据
      Object.keys(this.leaseform).forEach((key) => (this.leaseform[key] = ""));
      delete this.leaseform.renewal; // 默认不传参(非续签合同)
      this.leaseId = -1;
      this.isRenew = false;
      this.Copyleaseform = {};
      this.isOnceperiodsTotal = false;
      this.leaseform.transferFlag = 0;
      this.leaseFormPaymentState = false;
      this.formLoading = false;
    },
    // 关闭获取车辆信息弹窗
    hiddlenCarDialog() {
      Object.keys(this.getCarform).forEach((key) => (this.getCarform[key] = ""));
      this.carDataList = [];
    },

    // 打开获取车辆信息弹窗
    showCarinfo() {
      this.innerVisible = true;
      propertyData({ hideChecked: true }, this.carpagenum, this.carsize).then((res) => {
        console.log(res);
        // 分割购买金额
        res.data.dataList.forEach((ele) => {
          const contractAmount = ele.contractAmount;
          ele.contractAmount = contractAmount != null ? Number(contractAmount).toLocaleString() : "";
        });
        this.carDataList = res.data.dataList;
      });
    },

    // 查询车信息
    searchCarList() {
      const date = { hideChecked: true };
      for (const key in this.getCarform) {
        // console.log(this.queryFrom[key]);
        if (this.getCarform[key] != "") {
          date[key] = this.getCarform[key];
        }
      }
      propertyData(date, this.carpagenum, this.carsize).then((res) => {
        console.log(res);
        this.$message.success("查询成功");
        // 分割购买金额
        res.data.dataList.forEach((ele) => {
          const contractAmount = ele.contractAmount;
          ele.contractAmount = contractAmount != null ? Number(contractAmount).toLocaleString() : "";
        });

        this.carDataList = res.data.dataList;
      });
    },
    // 选取该车辆信息
    enterCarId(id, carframeNo) {
      console.log(id);
      if (id) {
        this.leaseform.assetsId = id;
        this.leaseform.carframeNo = carframeNo;
        this.innerVisible = false;
      }
    },
    // 查询合同历史版本列表
    ShowVhistory() {
      getcontractHistory(this.leaseId).then((res) => {
        console.log(res);
        this.leaseVersionData = res.data.dataList;
        this.versionVisible = true;
      });
    },
    // 点击预览按钮
    showInner(id, version) {
      console.log(version);

      getcontractSingle(id).then((res) => {
        console.log(res);
        res.data.irr = this.irrFormatt(res.data.irr);

        // 分割合同金额
        const contractAmount = res.data.contractAmount;
        res.data.contractAmount = contractAmount != null ? Number(contractAmount).toLocaleString() : "";

        // 分割月租金
        const monthRent = res.data.monthRent;
        res.data.monthRent = monthRent != null ? Number(monthRent).toLocaleString() : "";

        this.showInnerData = res.data;
        this.newShowVersion = version;
        this.singleVersionData = true;
      });
    },

    // 隐藏预览信息弹窗
    hiddlenMinidislog() {
      console.log("hhhhhh");
      this.newShowVersion = "";
      this.showInnerData = [];
    },

    // 删除合同信息
    removeLese(id) {
      this.$confirm("此操作将删除该租赁合同信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteLeaseDate(id).then((res) => {
            console.log(res);
            // this.pagenum = 1;
            // this.size = 10;
            this.getLeaseData();
            this.$message.success("已成功删除");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 设置历史版本为最新版本
    setVersionToNew(id) {
      updateIdNew(id).then((res) => {
        console.log(res);
        this.versionVisible = false;
        this.dialogFormVisible = false;
        // this.pagenum = 1;
        // this.size = 10;
        this.getLeaseData();
        this.$message.success("已设置当前版本为最新版");
      });
    },

    // 续签合同
    renew(id) {
      console.log("当前合同", id);
      this.leaseId = id;
      this.isRenew = true;
      //备份leasefrom
      let Copyleaseform = this.leaseform;
      getidleaseData(id).then((res) => {
        console.log(res);
        res.data.irr = this.irrFormatt(res.data.irr);

        // 重置表单数据
        for (const key in this.leaseform) {
          // console.log(res.data[key]);
          this.leaseform[key] = res.data[key];
        }

        for (const key in this.leaseform) {
          this.Copyleaseform[key] = res.data[key];
        }
        // 结束合同 -- 保持续签标识
        this.Copyleaseform.renewal = res.data.renewal;

        this.upadateComponentBusiness();
        this.leaseform.renewal = 1; // 手动续签合同
        // 重置新选填项
        this.leaseform.contractNo = "";
        this.leaseform.signDate = "";

        // 起租和结束延后一年
        const continueStartDate = this.continueYear(res.data.startDate);
        const continueEndDate = this.continueYear(res.data.endDate);

        this.leaseform.startDate = continueStartDate;
        this.leaseform.endDate = continueEndDate;

        this.dialogFormVisible = true;
      });
    },

    // 日期延迟一年
    continueYear(date) {
      // console.log(date.split("-"));
      // const dateArray = date.split("-");
      // const newyear = dateArray.length == 3 ? parseInt(dateArray[0]) + 1 : "1970";
      // dateArray.splice(0, 1, newyear);
      // // console.log(newyear, dateArray);
      // const newdate = dateArray.join("-");
      // console.log(newyear, newdate);
      if (date == null || date == "") {
        return "";
      } else {
        console.log(date);
        let DataList = date.split("-");
        let endYear = Number(DataList[0]);
        let endMonth = DataList[1];
        let endDay = DataList[2];
        if (DataList[1] == "02" && DataList[2] == "29") {
          endDay = "28";
          endYear = endYear + 1;
        } else {
          endYear = endYear + 1;
        }
        let newdate = endYear + "-" + endMonth + "-" + endDay;
        return newdate;
      }
    },
    // 查看还款日列表
    showdueDate() {
      if (this.leaseform.startDate !== "") {
        let dd = "";
        let mm = "";
        let YYYY = "";
        const nowstartDate = this.$moment(this.leaseform.startDate).valueOf();
        const targrtYY = this.$moment(nowstartDate).format("YYYY");
        const targrtMM = this.$moment(nowstartDate).format("M");
        const targrtDD = this.$moment(nowstartDate).format("D");
        // 生成指定日(几号)
        switch (true) {
          case targrtDD >= 2 && targrtDD <= 5:
            dd = 5;
            break;
          case targrtDD >= 6 && targrtDD <= 10:
            dd = 10;
            break;
          case targrtDD >= 11 && targrtDD <= 15:
            dd = 15;
            break;
          case targrtDD >= 16 && targrtDD <= 20:
            dd = 20;
            break;
          case targrtDD >= 21 && targrtDD <= 25:
            dd = 25;
            break;
          case targrtDD >= 26 && targrtDD <= 31:
            dd = 1;
            break;
          case targrtDD == 1:
            dd = 1;
            break;
        }
        // console.log(this.$moment(nowstartDate).format("yyyy-MM-DD"));

        const DateList = [];
        mm = parseInt(targrtMM);
        YYYY = parseInt(targrtYY);
        for (var i = 0; i < 12; i++) {
          if (i == 0 && targrtDD >= 26 && targrtDD <= 31) {
            mm += 2;
          } else {
            mm++;
          }
          if (mm > 12) {
            YYYY += 1;
            mm = 1;
          }

          if (mm <= 9) mm = "0" + mm;
          if (dd <= 9) dd = "0" + parseInt(dd);
          DateList.push({
            index: i + 1,
            date: `${YYYY}-${mm}-${dd}`,
          });
        }
        console.log(DateList);
        this.dueDateList = DateList;
      } else {
        this.dueDateList = [];
      }
    },
    //确认起租日期--计算结束日期
    startDateChange(startDate) {
      if (startDate == null || startDate == "") {
        this.leaseform.endDate = "";
      } else {
        console.log(startDate);
        // console.log(this.$moment(startDate).add(1, "y").subtract(1, "days").format("YYYY-MM-DD"));
        let endDate = "";
        let DataList = startDate.split("-");
        console.log(DataList); //判断闰年是否2-29
        if (DataList[1] == "02" && DataList[2] == "29") {
          endDate = this.$moment(startDate).add(1, "y").format("YYYY-MM-DD");
        } else {
          endDate = this.$moment(startDate).add(1, "y").subtract(1, "days").format("YYYY-MM-DD");
        }
        this.leaseform.endDate = endDate; //格式必须是"yyyy-MM-dd"
      }
    },
    // 查看当前剩余时间
    showresidueDate() {
      console.log("计算");
      if (this.leaseform.endDate !== "") {
        this.residueDate = this.computerDate(this.leaseform.endDate, this.leaseform.contractStatus) + "";
        // console.log(this.computerDate(this.leaseform.endDate));
      } else {
        this.residueDate = "0";
      }
    },

    // 监听合同状态
    leaseStateChange(state) {
      if (state != 2) {
        this.leaseform.caseNo = "";
      } else if (state != 3 || state != 4) {
        this.leaseform.stopDate = "";
      }
    },

    // 新增电子合同
    clickaddContract(assetsId) {
      let loadingQuery = Loading.service({
        lock: true,
        text: "加载中...",
        target: document.querySelector(".addClass .el-dialog"),
      });
      // 获取当前合同绑定公司
      FixedAssetsById(assetsId)
        .then((res) => {
          const companyId = res.data.companyId;
          // 获取甲方公司默认抬头信息
          getContractHeaderInf(companyId)
            .then((res) => {
              this.initialcontractform = Object.assign({}, this.contractform);
              this.contractform.companyName = res.data.companyTitle;
              this.contractform.companyAddress = res.data.companyAddress;
              this.contractform.companyPhone = res.data.companyPhone;
              this.contractform.companyBankAccount = res.data.companyBankAccount;
              this.contractform.companyBank = res.data.companyBank;

              loadingQuery.close();
              this.dialogContractVisible = true;
            })
            .catch((err) => loadingQuery.close());
        })
        .catch((err) => loadingQuery.close());
    },
    SaveContractData() {
      if (this.lesePreviewData.pdfPath == null) {
        this.$refs.ruleContForm.validate(async (valid) => {
          // 判断当前是否生成保证合同 电子新增/编辑 && (保证新增/编辑)
          // 选择保证人~
          if (this.ensureChecked) {
            const ensureValid = await this.ruleBzContractPDF();
            if (valid && ensureValid) {
              addContract(this.contractform, this.leaseId).then((res) => {
                console.log(res);
                this.$message.success("新增电子合同成功");
                this.getLeaseData(); // 更新合同审核状态
                const { companyName, companyAddress } = this.contractform;
                genBzContractPDF({ ...this.ensureform, companyName, companyAddress }, this.leaseId).then(async (res) => {
                  console.log(res);
                  await this.changeYcBzContract();
                  this.$message.success("新增保证合同成功");
                  this.dialogContractVisible = false;
                  this.PreviewVisible = false;
                });
              });
            }
          } else {
            if (valid) {
              addContract(this.contractform, this.leaseId).then((res) => {
                console.log(res);
                this.$message.success("新增电子合同成功");
                this.getLeaseData();
                this.dialogContractVisible = false;
                this.PreviewVisible = false;
              });
            }
          }
        });
      } else {
        this.$refs.ruleContForm.validate(async (valid) => {
          // 是否生成保证合同
          // 更新电子&&生成保证
          if (this.lesePreviewData.bzPdfPath === null) {
            if (this.ensureChecked) {
              const ensureValid = await this.ruleBzContractPDF();
              if (valid && ensureValid) {
                updateContractPDF(this.contractform, this.leaseId).then((res) => {
                  console.log(res);
                  this.$message.success("更新电子合同成功");
                  this.getLeaseData();
                  const { companyName, companyAddress } = this.contractform;
                  genBzContractPDF({ ...this.ensureform, companyName, companyAddress }, this.leaseId).then(async (res) => {
                    console.log(res);
                    await this.changeYcBzContract();
                    this.$message.success("新增保证合同成功");
                    this.dialogContractVisible = false;
                    this.PreviewVisible = false;
                  });
                });
              }
            } else {
              updateContractPDF(this.contractform, this.leaseId).then((res) => {
                console.log(res);
                this.$message.success("更新电子合同成功");
                this.getLeaseData();
                this.dialogContractVisible = false;
                this.PreviewVisible = false;
              });
            }
          } else {
            if (this.ensureChecked) {
              const ensureValid = await this.ruleBzContractPDF();
              if (valid && ensureValid) {
                updateContractPDF(this.contractform, this.leaseId).then((res) => {
                  console.log(res);
                  this.$message.success("更新电子合同成功");
                  this.getLeaseData();
                  const { companyName, companyAddress } = this.contractform;
                  updateBzContractPDF({ ...this.ensureform, companyName, companyAddress }, this.leaseId).then(async (res) => {
                    console.log(res);
                    await this.changeYcBzContract();
                    this.$message.success("更新保证合同成功");
                    this.dialogContractVisible = false;
                    this.PreviewVisible = false;
                  });
                });
              }
            }
          }
        });
      }
    },
    // 生成或更新保证合同
    changeYcBzContract() {
      const isYcBzContract = this.lesePreviewData.ycbzPdfPath;
      const { companyName, companyAddress } = this.contractform;
      const ensureform = _.omit(_.cloneDeep(this.ensureform), ["guaranteeType"]);
      // console.log(ensureform);
      // 是否开启有偿
      if (this.ycBzensureChecked) {
        if (isYcBzContract === null) {
          genYcbzContractPDF({ ...ensureform, companyName, companyAddress }, this.leaseId).then((res) => {
            this.$message.success("生成有偿保证合同成功");
          });
        } else {
          updateYcbzContractPDF({ ...ensureform, companyName, companyAddress }, this.leaseId).then((res) => {
            this.$message.success("更新有偿保证合同成功");
          });
        }
      }
    },
    // 校验保证合同信息字段~
    ruleBzContractPDF() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleEnsureForm.validate((ensureValid) => {
          resolve(ensureValid);
        });
      });
    },
    // 切换担保人
    guaranteeTypeArrChange(arr) {
      if (!arr.includes("company")) {
        Object.keys(this.ensureform).forEach((key) => (this.ensureform[key] = ""));
      }
      this.ensureform.guaranteeTypeArr = arr;
    },
    //更新电子合同
    updateContract() {
      this.initialcontractform = Object.assign({}, this.contractform);
      getContract(this.leaseId).then((res) => {
        console.log(res);
        this.contractform = res.data;
        if (this.lesePreviewData.bzPdfPath) {
          this.ensureChecked = true; // 回显是否已生成保证合同
          getBzContractSupplemenById(this.leaseId).then((res) => {
            console.log(res);
            let ensureFrom = Object.assign({}, res.data);
            delete ensureFrom.contractId;
            delete ensureFrom.createDate;
            // delete ensureFrom.guaranteeType;
            delete ensureFrom.updateDate;
            // 接口字段定义不统一guaranteeType
            ensureFrom.guaranteeTypeArr = JSON.parse(ensureFrom.guaranteeType.replace(/([a-zA-Z0-9_]+)/g, '"$1"'));
            this.ensureform = ensureFrom;
            this.dialogContractVisible = true;
          });
        } else {
          this.ensureChecked = false;
          this.dialogContractVisible = true;
        }
      });
    },
    // 生成二手车买卖合同
    addEscmmPdf() {
      if (this.lesePreviewData.newDegree === "新车") {
        this.$message.warning("适用车辆新旧度为新车，暂不能生成二手车买卖合同!");
        return false;
      }
      if (this.lesePreviewData.newDegree === null || this.lesePreviewData.newDegree === "") {
        this.$message.warning("适用车辆新旧度为空，请补录后再生成二手车买卖合同!");
        return false;
      }
      genEscmmContractPDF(this.leaseId).then((res) => {
        this.ReadLese(this.leaseId);
        this.$message.success("二手车买卖合同生成成功");
      });
    },
    // 更新二手车买卖合同
    updateEscmmPdf() {
      updateEscmmContractPDF(this.leaseId).then((res) => {
        this.ReadLese(this.leaseId);
        this.$message.success("二手车买卖合同已抓取系统最新字段值，更新成功！");
      });
    },
    // 下载电子合同
    DownLoadContract() {
      var _this = this;
      let loadingDown = Loading.service({
        lock: true,
        text: "下载中...",
        target: document.querySelector(".addClass .el-dialog"),
      });
      downContractPDF(this.leaseId)
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          // const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = this.lesePreviewData.pdfPath; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          // this.$message.success("正在下载...");
          loadingDown.close();
        })
        .catch((err) => {
          console.log(err);
          loadingDown.close();
        });
    },
    // 下载二手车买卖合同
    DownLoadEcmmPdf() {
      var _this = this;
      let loadingDown = Loading.service({
        lock: true,
        text: "下载中...",
        target: document.querySelector(".addClass .el-dialog"),
      });
      downloadEscmmContractPDF(this.leaseId)
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          // const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = this.lesePreviewData.escmmPdfPath; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          // this.$message.success("正在下载...");
          loadingDown.close();
        })
        .catch((err) => {
          console.log(err);
          loadingDown.close();
        });
    },
    // 还款说明书
    clickaddHksmsPdfPath() {
      genHksmsPDF(this.leaseId).then((res) => {
        this.ReadLese(this.leaseId);
        this.$message.success("还款说明书生成成功");
      });
    },
    updateHksmsPdfPath() {
      updateHksmstPDF(this.leaseId).then((res) => {
        this.ReadLese(this.leaseId);
        this.$message.success("还款说明书已抓取系统最新字段值，更新成功！");
      });
    },
    DownLoadHksmsPdfPath() {
      var _this = this;
      let loadingDown = Loading.service({
        lock: true,
        text: "下载中...",
        target: document.querySelector(".addClass .el-dialog"),
      });
      downloadHksmsPDF(this.leaseId)
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          // const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = this.lesePreviewData.hksmsPdfPath; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          // this.$message.success("正在下载...");
          loadingDown.close();
        })
        .catch((err) => {
          console.log(err);
          loadingDown.close();
        });
    },
    // 下载保证合同
    DownLoadBzContractPDF() {
      var _this = this;
      let loadingDown = Loading.service({
        lock: true,
        text: "下载中...",
        target: document.querySelector(".addClass .el-dialog"),
      });
      downloadBzContractPDF(this.leaseId)
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          // const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = this.lesePreviewData.bzPdfPath; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          // this.$message.success("正在下载...");
          loadingDown.close();
        })
        .catch((err) => {
          console.log(err);
          loadingDown.close();
        });
    },

    //下载有偿保证合同
    DownLoadycBzContractPDF() {
      var _this = this;
      let loadingDown = Loading.service({
        lock: true,
        text: "下载中...",
        target: document.querySelector(".addClass .el-dialog"),
      });
      downloadYcbzContractPDF(this.leaseId)
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          // const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = this.lesePreviewData.ycbzPdfPath; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          // this.$message.success("正在下载...");
          loadingDown.close();
        })
        .catch((err) => {
          console.log(err);
          loadingDown.close();
        });
    },

    // 电子合同弹窗隐藏
    hiddlenDialogContract() {
      this.$refs.ruleContForm.resetFields();
      this.contractform = this.initialcontractform;
      // 保证合同
      this.ensureChecked = false;
      this.ensureform.guaranteeTypeArr = [];
      this.$refs.ruleEnsureForm.resetFields();
      Object.keys(this.ensureform).forEach((key) => {
        if (key === "guaranteeTypeArr") {
          this.ensureform[key] = [];
        } else {
          this.ensureform[key] = "";
        }
      });
    },
    // 展开还款计划表
    showRepaymentDate() {
      // this.payPlanvisible = !this.payPlanvisible;
      // this.leasePaymentState = -1;
      // 展示的时候查询~
      // if (this.payPlanvisible) {
      const params = { contractId: this.leaseId };
      const planStateList = {
        1: "未付",
        2: "已付",
        3: "逾期未付",
        4: "逾期已付",
        5: "已付款-部分",
      };
      getRepanmentPlanList(params).then((res) => {
        console.log(res);
        const list = res.data;
        list.forEach((ele) => {
          ele.amount = ele.amount ? Number(ele.amount).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ele.amount;
          ele.repaymentDate = this.$moment(ele.repaymentDate).format("YYYY-MM-DD");
          ele.paymentDate = ele.paymentDate ? this.$moment(ele.paymentDate).format("YYYY-MM-DD") : "--";
          ele.stateVal = planStateList[ele.state];
          if (ele.state == 2) {
            this.leasePaymentState = 1;
          }
        });
        this.repanmentPlanList = list;
      });
      // }
    },
    // 隐藏第一期还款日弹窗
    repmentDateHide() {
      this.firstRepaymentDate = "";
    },
    // 点击修改按钮
    removePayment() {
      if (this.leasePaymentState > 0) {
        this.$message.warning("该租赁合同已开始还款,不能修改还款日!");
        return;
      }
      this.repmentDateVisible = true;
    },
    // 修改还款日
    setRepaymentDate() {
      console.log(this.firstRepaymentDate);
      if (this.firstRepaymentDate == null || this.firstRepaymentDate == "") {
        this.$message.warning("请输入第一期还款日");
        return;
      } else {
        updateRepaymentDate({ contractId: this.lesePreviewData.contractId, repaymentDate: this.firstRepaymentDate }).then((res) => {
          console.log(res);
          this.$message.success("修改成功");
          this.repmentDateVisible = false;
          this.payPlanvisible = false;
          this.PreviewVisible = false;
        });
      }
    },

    // 查看按钮
    ReadLese(leaseId) {
      console.log("合同id" + leaseId);
      this.leaseId = leaseId;
      getidleaseData(leaseId).then((res) => {
        console.log(res);
        res.data.irr = this.irrFormatt(res.data.irr);

        // 分割合同金额
        const contractAmount = res.data.contractAmount;
        res.data.contractAmount = contractAmount != null ? Number(contractAmount).toLocaleString() : "";

        // 分割月租金
        const monthRent = res.data.monthRent;
        res.data.monthRent = monthRent != null ? Number(monthRent).toLocaleString() : "";

        //保证合同
        // let bzPdfPath = res.data.bzPdfPath;
        // res.data.bzPdfPath = this.formatPdfPath(bzPdfPath, "保证合同");

        const pdfPathList = [
          { path: "pdfPath", name: "" },
          { path: "escmmPdfPath", name: "二手车买卖合同" },
          { path: "hksmsPdfPath", name: "还款说明书" },
          { path: "bzPdfPath", name: "保证合同" },
          { path: "ycbzPdfPath", name: "有偿保证合同" },
          { path: "fktzsPdfPath", name: "付款通知书" },
          { path: "fpqksmPdfPath", name: "发票情况说明" },
          { path: "czrdfcnhPdfPath", name: "承租人单方承诺函" },
        ];

        pdfPathList.forEach((item) => {
          let Path = res.data[item.path];
          res.data[item.path] = this.formatPdfPath(Path, item.name);
          //有偿保证合同
          if (item.path === "ycbzPdfPath") {
            this.ycBzensureChecked = Path === null ? false : true;
          }
        });

        this.lesePreviewData = res.data;
        this.PreviewVisible = true;
      });
      this.showRepaymentDate();
    },
    // 转化电子相关合同路径
    formatPdfPath,
    // 跳转资产详情
    SkipProperty() {
      this.$router.push({
        name: "property",
        params: {
          id: this.lesePreviewData.assetsId,
          assetsId: this.lesePreviewData.assetsId,
        },
      });
      this.PreviewVisible = false;
    },
    // 隐藏查看弹窗
    PerviewhiddlenDialog() {
      this.PreviewVisible = false;
      this.lesePreviewData = {};
      this.repanmentPlanList = [];
      this.leaseId = -1;
      this.payPlanvisible = false;
      this.leasePaymentState = -1;
      this.isAnnotation = false;
    },

    // 下载excel表格
    DownExcel() {
      var _this = this;
      downContract(this.queryFromData)
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = "lease_" + newTime; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          this.$message.success("正在下载...");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 剩余提醒时间(sort排序)
    sortWarnTime(custom) {
      console.log(custom);
      // 升序
      if (custom.order == "ascending") {
        this.queryFrom.expirationReminderSort = "asc";
      } else if (custom.order == "descending") {
        // 降序
        this.queryFrom.expirationReminderSort = "desc";
      } else if (custom.order == undefined) {
        this.queryFrom.expirationReminderSort = "";
      }

      this.getLeaseData();
    },

    // 点击导入按钮
    uploadMembers() {
      this.$refs.files.click();
    },

    // 获取excel表格数据
    upload(e) {
      const uploadFile = this.$refs.files.files[0];

      var formData = new FormData();
      formData.append("file", uploadFile);
      console.log(uploadFile, e.target.files[0]);

      // 成功上传一次之后,将内容置空
      e.target.value = "";
    },

    // 切换业务模式
    businessModelChange(newValue) {
      if (newValue) {
        this.leaseform.depositInputValue = "";
      }
      if (newValue == 2 && this.leaseform.monthRent != "") {
        this.leaseform.depositInputValue = this.leaseform.monthRent * 3;
      }
      if (newValue == 3 && this.leaseform.monthRent != "") {
        this.leaseform.depositInputValue = this.leaseform.monthRent;
      }
    },

    // 切换保证合同
    ensureCheckedChange(e) {
      if (!e) {
        this.$refs.ruleEnsureForm.resetFields();
        this.ensureform.guaranteeTypeArr = [];
        this.ycBzensureChecked = false;
      }
    },
    // 生成还款计划表~
    addPaymentPlanList() {
      // 非存续状态合同~
      if (this.lesePreviewData.contractStatus != 1) {
        this.$message.error("合同非存续状态，无法生成账单!");
        return false;
      }
      addRepanmentPlanList(this.leaseId).then((res) => {
        this.ReadLese(this.leaseId);
        this.$message.success("还款计划表生成成功");
      });
    },

    // 生成付款通知书 发票情况说明 承租人单方承诺函
    clickAddPdfPath(path) {
      if (path === "fktzsPdf") {
        genFktzsPDF(this.leaseId).then((res) => {
          this.ReadLese(this.leaseId);
          this.$message.success("付款通知书生成成功");
        });
      } else if (path === "fpqksmPdf") {
        genFpqksmPDF(this.leaseId).then((res) => {
          this.ReadLese(this.leaseId);
          this.$message.success("发票情况说明生成成功");
        });
      } else if (path === "czrdfcnhPdf") {
        genCzrdfcnhPDF(this.leaseId).then((res) => {
          this.ReadLese(this.leaseId);
          this.$message.success("承租人单方承诺函生成成功");
        });
      }
    },

    //更新付款通知书 发票情况说明 承租人单方承诺函
    updatePdfPath(path) {
      if (path === "fktzsPdf") {
        updateFktzsPDF(this.leaseId).then((res) => {
          this.ReadLese(this.leaseId);
          this.$message.success("付款通知书更新成功");
        });
      } else if (path === "fpqksmPdf") {
        updateFpqksmPDF(this.leaseId).then((res) => {
          this.ReadLese(this.leaseId);
          this.$message.success("发票情况说明更新成功");
        });
      } else if (path === "czrdfcnhPdf") {
        updateCzrdfcnhPDF(this.leaseId).then((res) => {
          this.ReadLese(this.leaseId);
          this.$message.success("承租人单方承诺函更新成功");
        });
      }
    },

    // a链接下载pdf文档
    aClickFile(res, path) {
      // 1,创建blob对象
      const blob = new Blob([res], {
        type: "application/vnd.ms-excel;charset=utf-8",
      });
      const fileName = this.lesePreviewData[path]; // 文件名

      // 2, 创建类文件对象, 导入blob数据源
      const url = window.URL.createObjectURL(blob);

      // 生成a标签存入body,利用herf属性下载
      const dom = document.createElement("a");
      dom.style.display = "none";
      dom.href = url;
      dom.setAttribute("download", fileName);
      document.body.appendChild(dom);
      dom.click();
    },

    //下载付款通知书 发票情况说明 承租人单方承诺函
    DownLoadPdfPath(path) {
      let loadingDown = Loading.service({
        lock: true,
        text: "下载中...",
        target: document.querySelector(".addClass .el-dialog"),
      });
      if (path === "fktzsPdf") {
        downloadFktzsPDF(this.leaseId)
          .then(async (res) => {
            await this.aClickFile(res, "fktzsPdfPath");
            loadingDown.close();
          })
          .catch((err) => {
            loadingDown.close();
          });
      } else if (path === "fpqksmPdf") {
        downloadFpqksmPDF(this.leaseId)
          .then(async (res) => {
            await this.aClickFile(res, "fpqksmPdfPath");
            loadingDown.close();
          })
          .catch((err) => {
            loadingDown.close();
          });
      } else if (path === "czrdfcnhPdf") {
        downloadCzrdfcnhPDF(this.leaseId)
          .then(async (res) => {
            await this.aClickFile(res, "czrdfcnhPdfPath");
            loadingDown.close();
          })
          .catch((err) => {
            loadingDown.close();
          });
      }
    },
    // 申请核销
    ProvePayment(billData) {
      // console.log(billData);
      const { id } = billData;
      requestWriteoff(id).then((res) => {
        this.$message.success("申请核销成功");
        this.showRepaymentDate();
      });
    },
    // 修改计划表批注信息
    updateAnnotation() {
      this.annotationInput = this.lesePreviewData.planAnnotate || "";
      this.isAnnotation = !this.isAnnotation;
    },
    cancelAnnotation() {
      this.isAnnotation = false;
      this.annotationInput = "";
    },
    saveAnnotation() {
      if (this.annotationInput == "") {
        this.$message.warning("请输入批注信息");
        return;
      }
      if (this.annotationInput.length > 500) {
        this.$message.warning("批注信息不能超过500个字");
        return;
      }
      console.log("当前批注信息", this.annotationInput);
      // 保存批注信息
      setRepanmentPlanAnnotate({ planAnnotate: this.annotationInput }, this.leaseId).then((res) => {
        console.log(res);
        this.$message.success("批注保存成功");
        this.isAnnotation = false;
        this.annotationInput = "";
        this.ReadLese(this.leaseId);
      });
    },
    // 下载声明书
    DownLoadDqwghsysmsPath() {
      var _this = this;
      let loadingDown = Loading.service({
        lock: true,
        text: "下载中...",
        target: document.querySelector(".addClass .el-dialog"),
      });
      downloadDqwghsysmsPDF()
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
          const fileName = "新经营性租赁合同-声明书-关于到期未过户保险事宜-清洁版"; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();
          loadingDown.close();
        })
        .catch((err) => {
          console.log(err);
          loadingDown.close();
        });
    },
  },
};
</script>

<style lang="less" scoped>
.lease {
  padding: 60px 20px 20px 20px;
  .el-date-editor.el-input {
    width: 200px;
  }
  .table_header {
    padding-bottom: 16px;
  }
  .table-box {
    /deep/.el-table th > .cell {
      text-align: center;
    }

    /deep/.el-table .cell {
      text-align: center;
    }
  }
  .el-dialog .el-input {
    // width: 70%;
  }
  .slot_label {
    display: inline-block;
    line-height: 20px;
    min-width: 110px;
    text-align: start;
    .el-link {
      font-size: 12px;
    }
  }
  .slot_span::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .template-btn {
    background-color: #1890ff;
    color: white;
  }
}
.minititle {
  font-size: 12px;
  color: gray;
}
.preLink {
  margin-right: 20px;
}
.el-button--success {
  height: 30px;
  line-height: 0.4;
  // background-color: #1c84c6;
  border: 1px solid #ffffff;
  border-radius: 3px;
  span {
    font-family: Microsoft YaHei UI;
    color: #fff;
    font-size: 14px;
    letter-spacing: 1px;
  }
}
.el-button--success:hover {
  // background-color: #1c85c681;
}
.Money_input {
  // width: 200px !important;
}
.lease /deep/ .el-link--inner {
  margin: 0px;
}

.leaseFrominfo /deep/ .el-form-item__label {
  white-space: pre-line !important;
}

.leaseFrominfo .wordRol /deep/ .el-form-item__label {
  line-height: 20px !important;
}

.leaseFrominfo /deep/ .el-form-item__error {
  z-index: 1 !important;
}

.numberInput /deep/ .el-input__inner {
  line-height: 1px !important;
}

.isnumberInput /deep/ .el-input__inner {
  line-height: 1px !important;
  color: #a1a2a4;
}

.numrule /deep/ .el-input__inner {
  line-height: 1px !important;
}

::v-deep .numberInput input::-webkit-outer-spin-button,
::v-deep .numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep .numberInput input[type="number"] {
  -moz-appearance: textfield !important;
}

::v-deep .isnumberInput input::-webkit-outer-spin-button,
::v-deep .isnumberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep .isnumberInput input[type="number"] {
  -moz-appearance: textfield !important;
}

::v-deep .numrule input::-webkit-outer-spin-button,
::v-deep .numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep .numrule input[type="number"] {
  -moz-appearance: textfield !important;
}

.deadlineChecked {
  display: inline-block;
  margin-left: 30px;
}

.contractBox {
  margin: 20px 20px 0 20px;

  .el-icon-document {
    color: #409eff;
    font-weight: 600;
  }
  .contractItem {
    .el-button--mini {
      padding: 4px;
    }

    .pdfLink {
      margin-top: 6px;
      word-break: break-all;
      padding-right: 10px;
      height: 32px;
      display: flex;
      align-items: flex-start;
    }
  }

  .contractTitle {
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;

    span {
      margin: 0 10px 0 6px;
    }
  }
}
.contractDialog .dialog-footer {
  padding-right: 18px;
}

.guaranteeStyle {
  display: flex;
}

.guaranteeStyle /deep/ .el-form-item__label {
  width: 100px;
}

.guaranteeStyle /deep/ .el-form-item__content {
  height: 32px;
}

.planList {
  .el-icon-document {
    color: #409eff;
    font-weight: 600;
  }

  .contractTitle {
    margin: 20px 0;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .annotation-inf {
      display: flex;
      justify-content: flex-end;
      .annotation-text {
        background-color: #f5f7fa;
        border: 1px solid #e4e7ed;
        border-radius: 4px;
        color: #c0c4cc;
        cursor: not-allowed;
        padding: 4px;
        min-width: 430px;
      }
      .annotation-btn {
        width: 100px;
        margin-left: 10px;
        align-items: baseline;
        display: flex;
      }
      span {
        color: #909399;
        font-weight: normal;
        font-size: 12px;
      }
    }

    span {
      margin: 0 10px 0 6px;
    }

    .el-button--mini {
      padding: 4px;
    }

    .pdfLink {
      margin-top: 6px;
    }
  }
}

.pdfStateY {
  color: blue !important;
}

.pdfStateN {
  color: #909399 !important;
}

.YcbzContract {
  display: inline-block;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: left;
  box-sizing: border-box;
}

.form-dialog-footer {
  text-align: right;
}
</style>
