// 全部动态路由列表
/**
 * meta.parentName 父路由名称
 * 主路由 16个 子路由 2个
 * 菜单列表 17个(setting)
 * 
 */
export const asyncRoutes = [
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import(/* webpackChunkName: "login_home" */ "../components/dashboard.vue"),
        meta: {
            type: "user",
        },
    },
    {
        path: "/user",
        name: "user",
        component: () => import(/* webpackChunkName: "property_addcarinfo_editInfo" */ "../components/user.vue"),
        meta: {
            type: "admin",
            parentName: 'setting'
        },
    },
    {
        path: "/role",
        name: "role",
        component: () => import(/* webpackChunkName: "property_addcarinfo_editInfo" */ "../components/role.vue"),
        meta: {
            type: "admin",
            parentName: 'setting'
        },
    },
    {
        path: "/permit",
        name: "permit",
        component: () => import(/* webpackChunkName: "property_addcarinfo_editInfo" */ "../components/permit.vue"),
        meta: {
            type: "admin",
            parentName: 'setting'
        },
    },
    {
        path: "/property",
        name: "property",
        component: () => import(/* webpackChunkName: "property_addcarinfo_editInfo" */ "../components/property.vue"),
        meta: {
            type: "user",
        },
    },
    {
        path: "/lease",
        name: "lease",
        component: () => import(/* webpackChunkName: "lease" */ "../components/lease.vue"),
        meta: {
            type: "user",
        },
    },
    {
        path: "/reviewLease",
        name: "reviewLease",
        component: () => import(/* webpackChunkName: "reviewLease" */ "../components/reviewLease.vue"),
        meta: {
            type: "user",
        },
    },
    {
        path: "/provePayment",
        name: "provePayment",
        component: () => import(/* webpackChunkName: "provePayment" */ "../components/provePayment.vue"),
        meta: {
            type: "user",
        },
    },
    {
        path: "/renderResource",
        name: "renderResource",
        component: () => import(/* webpackChunkName: "renderResource" */ "../components/renderResource.vue"),
        meta: {
            type: "user",
        },
    },
    {
        path: "/stockreport",
        name: "stockreport",
        component: () => import(/* webpackChunkName: "stockreport" */ "../components/stockreport.vue"),
        meta: {
            type: "user",
        },
    },
    {
        path: "/addcarinfo",
        name: "addcarinfo",
        component: () => import(/* webpackChunkName: "property_addcarinfo_editInfo" */ "../components/AddCarInfo.vue"),
        meta: {
            type: "user",
            parentName: 'property'
        },
    },
    {
        path: "/editcarinfo",
        name: "editcarinfo",
        component: () => import(/* webpackChunkName: "property_addcarinfo_editInfo" */ "../components/editcarinfo.vue"),
        meta: {
            type: "user",
            parentName: 'property'
        },
    },
    {
        path: "/settlereport",
        name: "settlereport",
        component: () => import(/* webpackChunkName: "report" */ "../components/settlereport.vue"),
        meta: {
            type: "user",
        },
    },
    {
        path: "/propertypercent",
        name: "propertypercent",
        component: () => import(/* webpackChunkName: "report" */ "../components/propertypercent.vue"),
        meta: {
            type: "user",
        },
    },
    {
        path: "/settleearnings",
        name: "settleearnings",
        component: () => import(/* webpackChunkName: "report" */ "../components/settleearnings.vue"),
        meta: {
            type: "user",
        },
    },
    {
        path: "/exceptional",
        name: "exceptional",
        component: () => import(/* webpackChunkName: "exceptional" */ "../components/exceptionalProperty.vue"),
        meta: {
            type: "user",
        },
    },
    {
        path: "/customizedbill",
        name: "customizedbill",
        component: () => import(/* webpackChunkName: "bill" */ "../components/customizedBill.vue"),
        meta: {
            type: "user",
        },
    },
    {
        path: "/billrecord",
        name: "billrecord",
        component: () => import(/* webpackChunkName: "bill" */ "../components/billRecord.vue"),
        meta: {
            type: "user",
        },
    },
    {
        path: "/test",
        name: "test",
        component: () => import(/* webpackChunkName: "test" */ "../components/test.vue"),
        meta: {
            type: "user",
        },
    },
];

/**
 * 使用 role 确定当前用户是否具有权限
 * 
 * property的子路包含 addcarinfo editInfo
 * 默认规则 有property权限就有这两个路由权限
 */
function hasPermission(roles, route) {
    if (roles.some((role) => route.name === role.name)) {
        return true;
    }

    if (roles.some((role) => role.name === 'property')) {
        return route.meta.parentName === 'property'
    }
}

/**
 * 根据用户接口路由列表数据 生成实际路由列表~
 */
export const filterAsyncRoutes = (routes, roles) => {
    const res = [];
    routes.forEach((route) => {
        const tmp = { ...route };
        // 判断是否有权限
        if (hasPermission(roles, tmp)) {
            // 如果有权限，继续遍历子路由
            if (tmp.children) {
                // 递归调用filterAsyncRoutes函数，过滤子路由
                tmp.children = filterAsyncRoutes(tmp.children, roles);
            }
            // 将符合条件的路由添加到结果数组中
            res.push(tmp);
        }
    });
    // 返回结果数组
    return res;
};

/**
 * 展平Tree数据
 * 仅保留role为true的权限数据~
 *
 */

export function treeToArray(tree) {
    let res = [];
    for (const item of tree) {
        const { children, ...i } = item;
        if (children && children.length > 0) {
            res = res.concat(treeToArray(children));
        }
        if (i.role) {
            res.push(i);
        }
    }
    return res;
}

// 测试接口权限数据 侧边栏菜单列表
export const menuList = [
    {
        name: "dashboard",
        title: "首页",
        icon: "el-icon-s-home",
        role: true, //是否有权限
        children: [],
    },
    {
        name: "renderResource",
        title: "进件管理",
        icon: "el-icon-receiving",
        role: true, //是否有权限
        children: [
            {
                name: "renderResource",
                title: "进件管理",
                icon: "el-icon-receiving",
                role: true, //是否有权限
                children: [],
            },
        ],
    },
    {
        name: "setting",
        title: "系统管理",
        icon: "el-icon-s-tools",
        role: true, //是否有权限
        children: [
            {
                name: "user",
                title: "用户管理",
                icon: "el-icon-user",
                role: true, //是否有权限
                children: [],
            },
            {
                name: "role",
                title: "角色管理",
                icon: "iconfont icon-jiaoseguanli",
                role: true, //是否有权限
                children: [],
            },
            {
                name: "permit",
                title: "权限管理",
                icon: "iconfont icon-fenghuangxiangmutubiao_quanxian",
                role: true, //是否有权限
                children: [],
            },
        ],
    },
    {
        name: "propertyAdmin",
        title: "资产管理",
        icon: "el-icon-menu",
        role: true, //是否有权限
        children: [
            {
                name: "property",
                title: "资产管理",
                icon: "el-icon-menu",
                role: true, //是否有权限
                children: [],
            },
            {
                name: "exceptional",
                title: "异常资产",
                icon: "el-icon-s-release",
                role: true, //是否有权限
                children: [],
            },
            {
                name: "propertypercent",
                title: "资产占比",
                icon: "el-icon-pie-chart",
                role: true, //是否有权限
                children: [],
            }
        ],
    },
    {
        name: "leaseAdmin",
        title: "合同管理",
        icon: "el-icon-document",
        role: true, //是否有权限
        children: [
            {
                name: "lease",
                title: "租赁合同管理",
                icon: "el-icon-document",
                role: true, //是否有权限
                children: [],
            },
            {
                name: "reviewLease",
                title: "合同审核管理",
                icon: "el-icon-s-check",
                role: true, //是否有权限
                children: [],
            }
        ],
    },
    {
        name: "reportAdmin",
        title: "数据报表",
        icon: "el-icon-s-data",
        role: true, //是否有权限
        children: [
            {
                name: "stockreport",
                title: "存量报表",
                icon: "el-icon-s-data",
                role: true, //是否有权限
                children: [],
            },
            {
                name: "settlereport",
                title: "结清报表",
                icon: "el-icon-data-analysis",
                role: true, //是否有权限
                children: [],
            },
            {
                name: "settleearnings",
                title: "结清收益",
                icon: "el-icon-s-finance",
                role: true, //是否有权限
                children: [],
            }
        ],
    },
    {
        name: "billAdmin",
        title: "账单管理",
        icon: "el-icon-document-copy",
        role: true, //是否有权限
        children: [
            {
                name: "billrecord",
                title: "账单管理",
                icon: "el-icon-document-copy",
                role: true, //是否有权限
                children: [],
            },
            {
                name: "customizedbill",
                title: "定制账单",
                icon: "el-icon-document-add",
                role: true, //是否有权限
                children: [],
            },
            {
                name: "provePayment",
                title: "核销管理",
                icon: "el-icon-wallet",
                role: true, //是否有权限
                children: [],
            }
        ],
    }
];
