<template>
  <div id="user">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">用户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <!-- 搜索区域视图 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input clearable v-model="queryFrom.username" placeholder="用户名" class="input-with-select">
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input v-model="queryFrom.nickName" clearable placeholder="昵称 " class="input-with-select">
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryFrom.gender" clearable placeholder="性别">
            <el-option :key="item.sexId" v-for="item in optionsCity" :label="item.sexName" :value="item.sexName"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button class="searchButton" type="primary" size="medium" @click="searchList()">查询</el-button>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <div class="table_header">
        <el-button type="primary" round size="mini" icon="el-icon-plus" @click="adduserVisible=true">新增</el-button>
      </div>
      <!-- 用户管理列表 -->
      <el-table :data="userListData" style="width: 100%" border stripe :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,backgroundColor:'#eff3f8'}">
        <el-table-column prop="id" label="用户ID" width="100" align="center">
        </el-table-column>
        <el-table-column prop="username" label="用户名称" width="180">
        </el-table-column>
        <el-table-column prop="nickName" label="昵称">
        </el-table-column>
        <el-table-column prop="mobile" label="手机号">
        </el-table-column>
        <el-table-column prop="gender" label="性别" width="140">
        </el-table-column>
        <el-table-column prop="Switchstate" label="用户状态" width="200">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.Switchstate" active-color="#13ce66" inactive-color="#ff4949" @change="Switchange($event,scope.row.id)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editUserinfo(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="removeuser(scope.row.id)">删除</el-button>

            <el-popover popper-class="operate-popover" placement="left" trigger="click">
              <el-button size="mini" icon="el-icon-key" @click="resetPassword(scope.row.id)">重置密码</el-button>
              <el-button size="mini" icon="el-icon-set-up" @click="assignRoles(scope.row)">分配角色</el-button>
              <el-button size="mini" icon="el-icon-finished" @click="assignDataRoles(scope.row)">分配数据源</el-button>
              <el-button slot="reference" size="mini" type="success" style="#1c84c6;marginLeft:10px" icon="el-icon-edit-outline">更多操作</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum" :page-sizes="[1, 5, 10, 20]" :page-size="size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>

    <!-- 新增用户弹窗 -->
    <el-dialog width="40%" title="用户信息" :visible.sync="adduserVisible" @close='hiddlenCarDialog'>
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules">
        <el-form-item label="用户名" label-width="100px" prop="username">
          <el-input v-model="addForm.username" autocomplete="off" style="width:70%"></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="100px" prop="password">
          <el-input v-model="addForm.password" autocomplete="off" style="width:70%"></el-input>
        </el-form-item>
        <el-form-item label="昵称" label-width="100px">
          <el-input v-model="addForm.nickName" autocomplete="off" style="width:70%"></el-input>
        </el-form-item>
        <el-form-item label="手机号" label-width="100px" prop="mobile">
          <el-input v-model="addForm.mobile" autocomplete="off" style="width:70%"></el-input>
        </el-form-item>
        <el-form-item label="性别" label-width="100px">
          <el-select v-model="addForm.gender" placeholder="请选择用户性别">
            <el-option :key="item.sexId" v-for="item in optionsCity" :label="item.sexName" :value="item.sexName"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="adduserVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUserApi" :loading="submitLoading">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 更新用户弹窗 -->
    <el-dialog width="40%" title="用户信息" :visible.sync="changeuserVisible" @close='hiddlenDialog'>
      <el-form :model="updateUser" ref="updateFormRef" :rules="updateFormRules">
        <el-form-item label="用户名" label-width="100px" prop="username">
          <el-input class="update" v-model="updateUser.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item class="update" label="密码" label-width="100px" prop="password">
          <el-input v-model="updateUser.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item class="update" label="昵称" label-width="100px">
          <el-input v-model="updateUser.nickName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item class="update" label="手机号" label-width="100px" prop="mobile">
          <el-input v-model="updateUser.mobile" autocomplete="off" style="width:70%"></el-input>
        </el-form-item>
        <el-form-item label="性别" label-width="100px">
          <el-select v-model="updateUser.gender" placeholder="请选择用户性别">
            <el-option :key="item.sexId" v-for="item in optionsCity" :label="item.sexName" :value="item.sexName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户状态" label-width="100px">
          <el-switch v-model="updateUser.Switchstate" active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="changeuserVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateUserApi" :loading="submitLoading">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 绑定角色列表弹窗 -->
    <el-dialog width="50%" title="角色信息" :visible.sync="rolesVisible" @close='roleHiddlenDialog' class="userrolesDialog">
      <el-descriptions>
        <el-descriptions-item label="用户名称">{{userInfo.username}}</el-descriptions-item>
        <el-descriptions-item label="昵称">{{userInfo.nickName}}</el-descriptions-item>
      </el-descriptions>
      <el-table :data="rolesList" style="width: 100%;fontSize: 14px;" border>
        <el-table-column prop="id" label="角色ID" width="80">
        </el-table-column>
        <el-table-column prop="roleName" label="角色名称" width="180">
        </el-table-column>
        <el-table-column prop="keyword" label="权限字符">
        </el-table-column>
        <el-table-column prop="description" label="说明">
        </el-table-column>
        <el-table-column label="绑定状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.roleSate" active-color="#13ce66" inactive-color="#ff4949" @change="roleSwitch($event,scope.row.id)"></el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 数据源弹窗 -->
    <el-dialog width="50%" title="分配数据源" :visible.sync="dataSourceVisible" @close='sourceHiddlenDialog' class="userSourceDialog">
      <div class="source-userinf">
         <el-descriptions>
          <el-descriptions-item label="用户名称">{{userInfo.username}}</el-descriptions-item>
          <el-descriptions-item label="昵称">{{userInfo.nickName}}</el-descriptions-item>
        </el-descriptions>
      </div>
      <ul class="source-header">
          <li class="source-header-item">分配出资人</li>      
      </ul>
      <div class="source-trnasfer">
        <el-transfer v-model="assignInvestor" :data="investors" :props="{key:'investorId',label:'investorName'}" :titles="['待分配出资人', '已分配出资人']"></el-transfer>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dataSourceVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateCitySource">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
    
<script>
import {
  UserList,
  AddUser,
  DeleteUser,
  changeUser,
  bindRole,
  unBindRole,
  getBindUserSouce,
  bindUserSouce
} from "@/api/user.js";
import { userRoleList, RoleList } from "@/api/role.js";
import { investorList } from "@/api/index.js";
import { Loading } from "element-ui";
export default {
  name: "",
  data() {
    return {
      userListData: [], //用户列表数据
      queryFrom: {
        username: "", //用户名
        nickName: "", //昵称
        gender: "", //性别
      },
      optionsCity: [
        { sexId: 1, sexName: "男" },
        { sexId: 2, sexName: "女" },
      ],
      pagenum: 1,
      size: 10,
      total: 0,

      adduserVisible: false, //添加用户弹窗
      addForm: {},
      addFormRules: {
        username: [
          { required: true, message: "请填写用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请填写密码", trigger: "blur" }],
        mobile: [
          {
            message: "手机号格式错误",
            pattern: /^1[3-9]\d{9}$/,
            trigger: "blur",
          },
        ],
      },

      changeuserVisible: false, //更新用户弹窗
      updateUser: {
        username: "",
        password: "",
        nickName: "",
        gender: "",
        mobile:'',
        Switchstate: "",
      },
      updateFormRules: {
        username: [
          { required: true, message: "请填写用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请填写密码", trigger: "blur" }],
        mobile: [
          {
            message: "手机号格式错误",
            pattern: /^1[3-9]\d{9}$/,
            trigger: "blur",
          },
        ],
      },
      updateUserId: "", //更新用户Id

      rolesList: [], //绑定角色列表
      rolesVisible: false, //
      userId: "", //用户绑定角色使用id
      userInfo: {
        username: "",
        nickName: "",
      },
      dataSourceVisible: false, //数据权限弹窗
      investors: [], //出资人列表
      assignInvestor: [], //已分配的出资人数据源
      submitLoading: false
    };
  },
  computed: {
    //动态遍历需要传的查询参数
    queryFromData() {
      let date = {};
      for (const key in this.queryFrom) {
        if (this.queryFrom[key] != "") {
          date[key] = this.queryFrom[key];
        }
      }
      return date;
    },
  },
  created() {
    this.getUserList();
  },
  methods: {
    getUserList() {
      UserList(this.queryFromData, this.pagenum, this.size)
        .then((result) => {
          console.log(result);
          result.data.dataList.map((ele) => {
            ele.createDate = this.$moment(ele.createDate).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            ele.Switchstate = ele.state == 1 ? true : false;
          });
          this.userListData = result.data.dataList;
          this.total = result.data.total;
        })
        .catch((err) => {});
    },
    //刷新数据
    refreshData() {
      this.size = 10;
      this.pagenum = 1;
      this.getUserList();
    },

    //查询列表
    searchList() {
      this.refreshData();
    },

    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.size = newSize;
      this.getUserList(); //发起数据请求
    },
    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.getUserList(); //发起数据请求
    },
    //隐藏新增用户弹窗
    hiddlenCarDialog() {
      this.addForm = {};
      this.$refs.addFormRef.resetFields(); ///重置新增用户表单
      this.submitLoading = false;
    },
    //调用新增用户接口
    addUserApi() {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          AddUser(this.addForm).then((res) => {
            console.log(res);
            this.adduserVisible = false;
            this.$message.success("用户新增成功");
              this.refreshData();
              this.submitLoading = false;
          });
        }
      });
    },

    //删除用户
    removeuser(id) {
      console.log(id);
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeleteUser({}, id).then((res) => {
            console.log(res);
            this.refreshData();

            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //监听Switch状态
    Switchange(val, id) {
      console.log(val, id);
      changeUser({ state: val ? "1" : "0" }, id).then((res) => {
        console.log(res);
        this.$message.success("用户状态已更新");
      });
    },

    //更新用户
    editUserinfo(olduserData) {
      console.log(olduserData);
      let { username, nickName, Switchstate, gender,mobile, id } = olduserData;
      this.updateUser.username = username;
      this.updateUser.nickName = nickName;
      this.updateUser.Switchstate = Switchstate;
      this.updateUser.gender = gender;
      this.updateUser.mobile = mobile;
      this.updateUserId = id;
      this.changeuserVisible = true;
      this.submitLoading = false;
    },

    //确认更新用户
    updateUserApi() {
      this.$refs.updateFormRef.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          this.updateUser.state =
            this.updateUser.Switchstate == true ? "1" : "0";
          changeUser(this.updateUser, this.updateUserId).then((res) => {
            console.log(res);
            this.changeuserVisible = false;
            this.$message.success("用户编辑成功");
            this.refreshData();
            this.submitLoading = false;
          });
        }
      });
    },

    //重置密码
    resetPassword(id) {
      changeUser({ password: 123456 }, id).then((res) => {
        console.log(res);
        this.$message.success("用户密码重置成功");
      });
    },

    //点击分配角色按钮
    assignRoles(userINfo) {
      console.log(userINfo);
      let { id, username, nickName } = userINfo;
      this.userInfo.username = username;
      this.userInfo.nickName = nickName;
      userRoleList(id).then((res) => {
        console.log(res);
        let oldAssign = res.data;
        RoleList({}, 1, 10).then((res) => {
          console.log(res);
          let allAssign = res.data.dataList;
          if (allAssign.length > 0) {
            allAssign.some((element) => {
              if (oldAssign.length > 0) {
                oldAssign.some((item) => {
                  if (item.id === element.id) {
                    element.roleSate = true;
                    return true; //符合要求跳出循环
                  } else {
                    element.roleSate = false;
                  }
                });
              } else {
                element.roleSate = false;
              }
            });
          }
          this.rolesList = allAssign;
          this.userId = id;
          this.rolesVisible = true;
          console.log(allAssign);
        });
      });
    },

    //修改用户==角色绑定
    roleSwitch(rolestate, roleId) {
      console.log(rolestate, roleId);
      if (rolestate) {
        //绑定角色
        bindRole({ userId: this.userId, roleId: roleId }).then((res) => {
          console.log(res);
          this.$message.success("角色绑定成功");
        });
      } else {
        unBindRole({ userId: this.userId, roleId: roleId }).then((res) => {
          this.$message.success("角色解绑成功");
        });
      }
    },

    //隐藏更新用户弹窗
    hiddlenDialog() {
      this.$refs.updateFormRef.resetFields();
      this.$refs.updateFormRef.clearValidate(); ///重置新增用户表单
      Object.keys(this.updateUser).forEach(
        (key) => (this.updateUser[key] = "")
      );
    },
    //隐藏角色弹窗
    roleHiddlenDialog() {
      this.userId = "";
      this.rolesList = [];
      this.userInfo = [];
    },
    // 分配数据源
    assignDataRoles(userINfo) {
      let loadingQuery = Loading.service({
        lock: true,
        text: "",
        target: document.querySelector(".userSourceDialog .el-dialog"),
      });
      let { id, username, nickName } = userINfo;
      this.userId = id;
      this.userInfo.username = username;
      this.userInfo.nickName = nickName;
      this.dataSourceVisible = true;
      investorList().then(async (res) => {
        const {dataList} = res.data;
        this.investors = dataList;
        const { data } = await getBindUserSouce({id}); // 获取已绑定出资人数据源
        this.assignInvestor = data.map(item => item.investorId);
        loadingQuery.close();
      })      
    },
    //隐藏分配数据源弹窗
    sourceHiddlenDialog() {
      this.assignInvestor = [];
      this.investors = [];
      this.userId = "";
      this.userInfo = {};
    },
    // 更新用户数据源__city
    updateCitySource() {
      console.log(this.assignInvestor);
      // if (this.assignInvestor.length === 0) {
      //   this.$message.warning("请选择出资人到已分配出资人列表");
      //   return;
      // }
      let loadingQuery = Loading.service({
        lock: true,
        text: "",
        target: document.querySelector(".userSourceDialog .el-dialog"),
      });
      const params = {
        id: this.userId,
        investorIds: this.assignInvestor
      }
      bindUserSouce(params).then((res) => {
        this.$message.success("数据源分配成功!");
        this.dataSourceVisible = false;
        loadingQuery.close();
      })
    }
  },
};
</script>
    
<style lang='less' scoped>
#user {
  padding: 60px 20px 20px 20px;
}

.source-header {
  padding-bottom: 16px;
  font-weight: 700;
  margin: 0;
  list-style-type: disclosure-closed;
}

.source-trnasfer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/deep/ .el-transfer-panel {
  width: 250px;
}
// /deep/ .el-transfer-panel__list {
//   height: 300px
// } 
/deep/ .el-transfer-panel__body {
  height: 250px;
}
.userSourceDialog /deep/ .el-dialog__body {
  padding: 20px;
}

.source-userinf {
  padding-left: 20px;
}
</style>